import DetailsCard from '@/components/DetailsCard';
import EditableTextnput from '@/components/EditableTextInput';
import HeroImage from '@/components/HeroImage';
import ManageProjectModal from '@/components/ManageProjectModal';
import ProjectsOverviewCard from '@/components/ProjectsOverviewCard';
import PurposeSelect from '@/components/PurposeSelect';
import { PURPOSE_SELECT_BEHAVIOR_CATEGORY } from '@/components/PurposeSelect/constants';
import { RoutesList } from '@/routes/routesList';
import { cleanCategoryForMutation } from '@/services/categories';
import { useUpdateCategoryMutation } from '@/services/categories/hooks';
import { useCreateProject, useProjectsByCategoryId } from '@/services/project/hooks';
import { IconFire, IconMountainTop, IconMyProjects, IconPlus, IconStarComet } from '@/theme/icons';
import { Category } from '@/types/category';
import { refetchCategoryAndBlocks } from '@/utils/category';
import rem from '@/utils/rem';
import {
  Button,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  Grid,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  chakra,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { Children, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

type Props = {
  category?: Category | undefined | null;
};

const M7FormSchema = z.object({
  ultimateVision: z
    .string()
    .trim()
    // .min(1, { message: 'Ultimate Vision cannot be empty' })
    // .min(5, { message: 'Please enter an Ultimate Vision that is 5 characters or more' })
    .max(10000, { message: 'Ultimate Vision must be 10000 characters or less' })
    .nullable()
    .optional(),
  role: z
    .string()
    .trim()
    // .min(1, { message: 'Role cannot be empty' })
    // .min(5, { message: 'Please enter a Role that is 5 characters or more' })
    .max(255, { message: 'Role must be 255 characters or less' })
    .nullable()
    .optional(),
  ultimatePurpose: z
    .string()
    .trim()
    // .min(1, { message: 'Ultimate Purpose cannot be empty' })
    // .min(5, { message: 'Please enter an Ultimate Purpose that is 5 characters or more' })
    .max(10000, { message: 'Ultimate Purpose must be 10000 characters or less' })
    .optional(),
  oneYearGoal: z
    .string()
    .trim()
    // .min(1, { message: '1 Year goal cannot be empty' })
    // .min(5, { message: 'Please enter a 1 Year goal that is 5 characters or more' })
    .max(10000, { message: 'One Year Goal must be 10000 characters or less' })
    .nullable()
    .optional(),
  ninetyDayGoal: z
    .string()
    .trim()
    // .min(1, { message: '90 days goal cannot be empty' })
    // .min(5, { message: 'Please enter a 90 days goal  that is 5 characters or more' })
    .max(10000, { message: '90 Days Goal must be 10000 characters or less' })
    .nullable()
    .optional(),
});

type FormData = z.infer<typeof M7FormSchema>;

const MotionDiv = chakra(motion.div);

const Magnificent7Section = ({ category, ...rest }: Props) => {
  const toast = useToast();
  const navigate = useNavigate();

  const IS_ARCHIVE_RGB = 'rgba(128, 128, 128, 0.5)';

  const isArchived = category?.isArchived;

  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, defaultValues },
  } = useForm<FormData>({
    defaultValues: {
      ultimateVision: category?.ultimateVision ?? null,
      role: category?.role ?? null,
      ultimatePurpose: category?.ultimatePurpose ?? '',
      oneYearGoal: category?.oneYearGoal ?? null,
      ninetyDayGoal: category?.ninetyDayGoal ?? null,
    },
    resolver: zodResolver(M7FormSchema),
    mode: 'onChange',
  });

  const {
    isOpen: isOpenCreateProjectModal,
    onOpen: openCreateProjectModal,
    onClose: onCloseCreateProjectModal,
  } = useDisclosure({ id: 'createNewProject' });

  const { mutateAsync: createProject, isLoading: isCreatingProject } = useCreateProject();
  const updateCategory = useUpdateCategoryMutation();

  const { data: projectByCategory } = useProjectsByCategoryId(category?.id ?? '', {
    enabled: !!category && !!category.id && category.id !== '',
  });

  const onSubmit = useCallback(
    (data: FormData) => {
      if (!category) {
        return;
      }

      updateCategory.mutate(
        {
          ...cleanCategoryForMutation(category),
          ultimateVision: data?.ultimateVision ?? null,
          role: data?.role ?? null,
          ultimatePurpose: data?.ultimatePurpose ?? '',
          oneYearGoal: data?.oneYearGoal ?? null,
          ninetyDayGoal: data?.ninetyDayGoal ?? null,
        },
        {
          onSuccess: () => {
            refetchCategoryAndBlocks();

            toast({
              title: 'Saved',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          },

          onError: (error) => {
            toast({
              title: error?.response?.errors?.[0]?.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [category, toast, updateCategory],
  );

  const onCreateProject = useCallback(
    async ({ name, purpose, result }: { name: string; result: string; purpose: string }) => {
      const id = uuidv4();

      if (!category?.id) return;

      await createProject({
        id,
        name,
        categoryId: category.id,
        result,
        purpose,
        imageUrl: category?.selectedImageUrl ?? '',
        isArchived: false,
      });

      navigate(`${RoutesList.ProjectsPage}/${id}`);
    },
    [category?.id, category?.selectedImageUrl, createProject, navigate],
  );

  const handleBlur = useCallback(
    (value: string | undefined | null, prevValue: string, field: keyof FormData) => {
      if (value === undefined) {
        setValue(field, prevValue);
      }
    },
    [setValue],
  );

  useEffect(() => {
    reset({
      ultimateVision: category?.ultimateVision ?? null,
      role: category?.role ?? null,
      ultimatePurpose: category?.ultimatePurpose ?? '',
      oneYearGoal: category?.oneYearGoal ?? null,
      ninetyDayGoal: category?.ninetyDayGoal ?? null,
    });
  }, [
    category?.ninetyDayGoal,
    category?.oneYearGoal,
    category?.role,
    category?.ultimatePurpose,
    category?.ultimateVision,
    reset,
  ]);

  return (
    <VStack {...rest} paddingBottom={rem(80)}>
      <MotionDiv
        initial={{ opacity: category ? 0 : 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        width="full"
        padding={0}
      >
        <HeroImage category={category} imageUrl={category?.selectedImageUrl} fadeIn isArchived={isArchived}>
          <Stack
            justifyContent="center"
            width={{ base: '70%', '2xl': '80%', '4xl': '60%' }}
            minHeight={rem(300)}
            paddingY={rem(50)}
          >
            <Flex
              width="fit-content"
              marginBottom={rem(22)}
              padding={rem(8)}
              opacity={isArchived ? '0.4' : '1'}
              borderRadius={4}
              backgroundColor="gray.50"
            >
              <Text textStyle="smallBlack" color="blue.1000" textTransform="uppercase">
                My ultimate vision
              </Text>
            </Flex>

            <Controller
              name="ultimateVision"
              control={control}
              render={({ field: { ref, value, onChange } }) => (
                <Editable
                  ref={ref}
                  textStyle="h2SatMedium"
                  display={isArchived && !value ? 'none' : ''}
                  overflow="unset"
                  width="full"
                  height="full"
                  fontSize={rem(50)}
                  lineHeight="110%"
                  borderRadius={rem(5)}
                  defaultValue={value ?? ''}
                  isDisabled={isArchived || isSubmitting}
                  onBlur={(preValue) => handleBlur(value, preValue, 'ultimateVision')}
                  onChange={onChange}
                  onSubmit={() => {
                    if (category?.ultimateVision !== value && value !== undefined) {
                      // submit empty string if prev value non empty, don't submit if it was empty
                      handleSubmit(onSubmit)();
                    }
                  }}
                  placeholder="What does my ideal, happiest life look like in this area?"
                  selectAllOnFocus={false}
                  submitOnBlur
                  value={value ?? ''}
                >
                  <EditablePreview
                    width="full"
                    height="full"
                    fontWeight={600}
                    opacity={value ? 1 : 0.5}
                    noOfLines={4}
                  />
                  <EditableTextarea
                    height="full"
                    padding={rem(10)}
                    fontWeight={600}
                    border={`${rem(1)} solid`}
                    borderColor="transparent"
                    _focusVisible={{
                      border: '2px solid',
                      borderColor: 'cyan.400',
                      boxShadow: 'none',
                      backgroundColor: 'background-primary',
                    }}
                    resize="none"
                    onFocus={(e) =>
                      e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                    }
                    rows={4}
                  />
                </Editable>
              )}
            />
            {errors?.ultimateVision?.message && (
              <Text as="span" color="red.500" fontSize="small">
                {errors?.ultimateVision?.message}
              </Text>
            )}
          </Stack>
        </HeroImage>

        <VStack position="relative" zIndex={10} gap={rem(16)} width="full" paddingX={[4, 8, 16]}>
          <SimpleGrid
            gap={rem(16)}
            width="full"
            columns={{
              base: 1,
              lg: 2,
            }}
          >
            <DetailsCard color={isArchived ? IS_ARCHIVE_RGB : category?.color} sectionTitle="My role" icon={IconFire}>
              {isArchived && !defaultValues?.role ? (
                <Text textStyle="smallBlack" color="gray.400" textAlign="center" width="100%">
                  No My Role added
                </Text>
              ) : (
                <EditableTextnput
                  isHidden={isArchived && !defaultValues?.role}
                  cursor={isArchived ? 'not-allowed' : undefined}
                  control={control}
                  fontSize={rem(17)}
                  isSubmitting={isArchived || isSubmitting}
                  name="role"
                  placeholder="e.g. “Networking Ninja”"
                  onBlur={(preValue: string, value: string | undefined) => handleBlur(value, preValue, 'role')}
                  onSubmit={(value: string | undefined): void => {
                    if (category?.role !== value && (!!value || !!category?.role)) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  isArchived={isArchived}
                />
              )}
              {errors?.role?.message && (
                <Text as="span" color="red.500" fontSize="small">
                  {errors?.role?.message}
                </Text>
              )}
            </DetailsCard>

            {/* Temporarily hidden upon request via RRI-2904. Consider removing permanently after June 30 */}
            {/* <Callout title="What is a Category?" variant="bordered">
              Categories are the highest level of your journey, helping you categorize actions into distinct buckets.
              Downstream, Projects can also be attached to Categories, creating a relationship between your Project,
              it's Actions, and their overall Category.
            </Callout> */}
          </SimpleGrid>

          <DetailsCard
            color={isArchived ? IS_ARCHIVE_RGB : category?.color}
            sectionTitle="My Ultimate Purpose"
            icon={IconStarComet}
          >
            <VStack alignItems="normal" width="full" spacing={rem(24)}>
              <Controller
                name="ultimatePurpose"
                control={control}
                render={({ field }) =>
                  isArchived && !defaultValues?.ultimatePurpose ? (
                    <Text textAlign="center" width="100%" textStyle="smallBlack" color="gray.400">
                      No My Ultimate Purpose added
                    </Text>
                  ) : (
                    <PurposeSelect
                      {...field}
                      behavior={PURPOSE_SELECT_BEHAVIOR_CATEGORY}
                      category={category || undefined}
                      placeholder="Why is this important to me? why is success in this area of my life an absolute “must” ?"
                      hideLabel={true}
                      label="My Ultimate Purpose"
                      error={errors?.ultimatePurpose?.message}
                      fontSize={rem(17)}
                      opacity={1}
                      isDisabled={isArchived}
                      color="text-primary"
                      borderBottomStyle={isArchived ? 'none' : 'solid'}
                      _placeholder={{
                        color: 'text-tertiary',
                        opacity: 0.5,
                        fontSize: rem(17),
                        fontWeight: 500,
                      }}
                    />
                  )
                }
              />
            </VStack>
          </DetailsCard>

          <SimpleGrid
            gap={rem(16)}
            width="full"
            columns={{
              base: 1,
              lg: 2,
            }}
          >
            <DetailsCard
              color={isArchived ? IS_ARCHIVE_RGB : category?.color}
              sectionTitle="One year goals"
              icon={IconMountainTop}
            >
              {isArchived && !defaultValues?.oneYearGoal ? (
                <Text textAlign="center" width="100%" textStyle="smallBlack" color="gray.400">
                  No One Year Goals added
                </Text>
              ) : (
                <EditableTextnput
                  isHidden={isArchived && !defaultValues?.oneYearGoal}
                  cursor={isArchived ? 'not-allowed' : undefined}
                  control={control}
                  fontSize={rem(17)}
                  isSubmitting={isArchived || isSubmitting}
                  name="oneYearGoal"
                  inputType="textarea"
                  placeholder="Add important one year results here"
                  onBlur={(preValue: string, value: string | undefined) => handleBlur(value, preValue, 'oneYearGoal')}
                  onSubmit={(value: string | undefined): void => {
                    if (category?.oneYearGoal !== value && (!!value || !!category?.oneYearGoal)) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  isArchived={isArchived}
                />
              )}
              {errors?.oneYearGoal?.message && (
                <Text as="span" color="red.500" fontSize="small">
                  {errors?.oneYearGoal?.message}
                </Text>
              )}
            </DetailsCard>

            <DetailsCard
              color={isArchived ? IS_ARCHIVE_RGB : category?.color}
              sectionTitle="90 day goals"
              icon={IconMountainTop}
            >
              {isArchived && !defaultValues?.ninetyDayGoal ? (
                <Text textAlign="center" width="100%" textStyle="smallBlack" color="gray.400">
                  No 90 Day Goals added
                </Text>
              ) : (
                <EditableTextnput
                  isHidden={isArchived && !defaultValues?.ninetyDayGoal}
                  cursor={isArchived ? 'not-allowed' : undefined}
                  control={control}
                  fontSize={rem(17)}
                  isSubmitting={isArchived || isSubmitting}
                  name="ninetyDayGoal"
                  inputType="textarea"
                  placeholder="Add important 90 day results here"
                  onBlur={(preValue: string, value: string | undefined) => handleBlur(value, preValue, 'ninetyDayGoal')}
                  onSubmit={(value: string | undefined): void => {
                    if (category?.ninetyDayGoal !== value && (!!value || !!category?.ninetyDayGoal)) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  isArchived={isArchived}
                />
              )}
              {errors?.ninetyDayGoal?.message && (
                <Text as="span" color="red.500" fontSize="small">
                  {errors?.ninetyDayGoal?.message}
                </Text>
              )}
            </DetailsCard>
          </SimpleGrid>

          {projectByCategory?.project && (
            <DetailsCard
              color={isArchived ? IS_ARCHIVE_RGB : category?.color}
              sectionTitle="My Projects"
              icon={IconMyProjects}
            >
              <Grid
                gridRowGap={rem(25)}
                gridColumnGap={rem(25)}
                width="full"
                style={{ gridTemplateColumns: `repeat(${isLargerThan1024 ? '3' : 'auto-fill'}, minmax(300px, 1fr))` }}
              >
                {Children.toArray(
                  projectByCategory.project.map((p) => (
                    <ProjectsOverviewCard project={p} location="categories" isArchived={isArchived || p.isArchived} />
                  )),
                )}
              </Grid>

              <Flex justifyContent="center" width="full" marginTop={rem(16)} marginBottom={4}>
                <Button
                  height="auto"
                  isDisabled={isArchived}
                  leftIcon={<Icon as={IconPlus} boxSize={rem(24)} />}
                  onClick={openCreateProjectModal}
                  paddingX={rem(32)}
                  paddingY={rem(16)}
                  variant="secondary"
                >
                  <Text textStyle="large" color="text-primary">
                    Create Project
                  </Text>
                </Button>
              </Flex>
            </DetailsCard>
          )}
        </VStack>
      </MotionDiv>

      {isOpenCreateProjectModal && (
        <ManageProjectModal
          isOpen={isOpenCreateProjectModal}
          isLoading={isCreatingProject}
          onSubmit={onCreateProject}
          onClose={onCloseCreateProjectModal}
          categoryId={category?.id}
          readonly
          readonlyTooltipLabel="The project is associated with this Category. If you want to create a project for another category please go in the Projects page"
        />
      )}
    </VStack>
  );
};

export default Magnificent7Section;
