import { IconTrash } from '@/theme/icons';
import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { Box, ButtonProps, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

type Props = {
  blockIsCompleted?: boolean;
  iconColor?: string;
  iconSize?: string;
  showButtonName?: boolean;
  withActionBlockCreationMenu?: boolean;
  tooltipText?: string;
  onExternalClick?: () => void;
};

const DeleteBulkActionsButton = ({
  backgroundColor,
  blockIsCompleted = false,
  iconColor = 'text-primary',
  iconSize = rem(14),
  showButtonName = false,
  withActionBlockCreationMenu = false,
  textStyle = 'small',
  tooltipText = 'Select actions to delete',
  onExternalClick,
  ...rest
}: Props & ButtonProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const openDeleteActionsModal = useCallback(() => {
    setIsTooltipOpen(false);

    if (onExternalClick) {
      onExternalClick();
      return;
    }
  }, [onExternalClick]);

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  if (!showButtonName) {
    return (
      <Tooltip pointerEvents="all" isOpen={isTooltipOpen} label={tooltipText} placement="top">
        <Box display="inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <IconButton
            minWidth="1.75em"
            height="1.75em"
            color="text-primary"
            textColor="stroke-primary"
            border={`${rem(1)} solid`}
            borderColor="stroke-primary"
            _hover={
              backgroundColor && {
                backgroundColor: getColorFromToken(String(backgroundColor)),
                borderColor: getColorFromToken(String(backgroundColor)),
                color: 'text-primary',
              }
            }
            cursor={blockIsCompleted ? 'not-allowed' : 'pointer'}
            aria-label={tooltipText}
            backgroundColor="transparent"
            icon={<Icon as={IconTrash} boxSize={iconSize} color={iconColor} />}
            isDisabled={blockIsCompleted}
            onClick={openDeleteActionsModal}
            variant="tertiary"
            {...rest}
          />
        </Box>
      </Tooltip>
    );
  }
};

export default DeleteBulkActionsButton;
