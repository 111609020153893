import ThreeDotsProjectButton from '@/components/ProjectDetail/ThreeDotsProjectButton';
import { CATEGORIES_ICONS } from '@/constants/category';
import { RoutesList } from '@/routes/routesList';
import { useCategories } from '@/services/categories/hooks';
import { ProjectType } from '@/types/project';
import { resolveCategoryImageByName } from '@/utils/category';
import { generateLinearGradient, getColorFromToken } from '@/utils/color';
import { fixUncategorizedName } from '@/utils/index';
import rem from '@/utils/rem';
import { Box, Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  project: ProjectType;
  setOpenDeleteModal: () => void;
  setOpenArchiveModal: () => void;
  setOpenEditModal: () => void;
};

function ProjectListItem({ project, setOpenArchiveModal, setOpenDeleteModal, setOpenEditModal }: Props) {
  const { data: categories } = useCategories();

  const category = useMemo(() => {
    if (!categories) {
      return null;
    }
    return categories.category.find((c) => c.id === project.categoryId);
  }, [categories, project.categoryId]);

  return (
    <Box position="relative" overflow="hidden">
      <Box
        minHeight={rem(223)}
        background={
          project.imageUrl
            ? `linear-gradient(180deg, ${getColorFromToken(category?.color, '0')} 0%, ${getColorFromToken(
                category?.color,
                '0.35',
              )} 60%), linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%), url(${resolveCategoryImageByName(
                project.imageUrl + (project.imageUrl.includes('images.unsplash.com') ? '&w=600&dpr=2&q=65' : ''),
              )}) center / cover no-repeat`
            : `linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%), ${generateLinearGradient(
                category?.color ?? 'gray.300',
                getColorFromToken(category?.color, '0.2', '900'),
                -360,
              )}`
        }
        borderBottom={`${rem(8)} solid`}
        borderBottomColor={category?.color}
        borderRadius={rem(8)}
        backgroundColor="background-tertiary"
        filter={project.isArchived ? 'grayscale(100%)' : 'none'}
      >
        <Link as={RouterLink} _hover={{ textDecoration: 'none' }} to={`${RoutesList.ProjectsPage}/${project.id}`}>
          <VStack
            alignItems="start"
            justifyContent="end"
            gap={rem(12)}
            width="full"
            height={rem(223)}
            paddingX={rem(20)}
            paddingY={rem(28)}
          >
            <VStack alignItems="start" gap={rem(4)}>
              <Flex
                alignItems="center"
                justifyContent="center"
                width={rem(28)}
                height={rem(28)}
                borderRadius="full"
                backgroundColor={category?.color ?? 'gray.300'}
              >
                <Icon
                  as={CATEGORIES_ICONS[category?.icon ?? 'uncategorized']}
                  width={rem(16)}
                  height={rem(16)}
                  color="blue.1000"
                />
              </Flex>
              <Text textStyle="xsmall" color="white" noOfLines={1}>
                {fixUncategorizedName(category?.name ?? '')}
              </Text>
            </VStack>

            <VStack alignItems="start" gap={rem(4)}>
              <Text textStyle="h4BarSB" color="white" textTransform="uppercase" noOfLines={1}>
                {project.name}
              </Text>
              <Text textStyle="small" color="white" opacity="0.75" noOfLines={1}>
                {project.result}
              </Text>
            </VStack>
          </VStack>
        </Link>
      </Box>

      <Box position="absolute" zIndex={2} top={rem(8)} right={rem(8)}>
        <ThreeDotsProjectButton
          onClickEdit={setOpenEditModal}
          onClickDelete={setOpenDeleteModal}
          onClickArchive={setOpenArchiveModal}
          border={0}
          backgroundColor="rgba(0, 0, 0, 0.10)"
          isArchived={project.isArchived}
        />
      </Box>
    </Box>
  );
}

export default ProjectListItem;
