import CategoryManagerOverviewCard from '@/components/CategoryManagerOverviewCard';
import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import DeleteArchiveCategoryModal from '@/components/DialogModal';
import {
  CATEGORY_ARCHIVE_CONFIRMATION_MODAL_COPY,
  CATEGORY_RESTORE_CONFIRMATION_MODAL_COPY,
} from '@/constants/archive';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { keys } from '@/gql/global/keys';
import { ARCHIVE_PROJECT } from '@/gql/project';
import { cleanCategoryForMutation } from '@/services/categories';
import { useCategories, useDeleteCategory, useUpdateCategoryMutation } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import { useProjects } from '@/services/project/hooks';
import { IconChevronDown, IconChevronRight, IconPlus } from '@/theme/icons';
import { Category } from '@/types/category';
import { refetchCategoryAndBlocks } from '@/utils/category';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { invalidateQueries, refetchQueries } from '@/utils/tanStackQuery';
import { trackCreateCategorySelected } from '@/utils/tracking';
import { Box, Button, Collapse, Flex, Grid, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DashboardLayout from 'src/layouts/Dashboard';

export const refetchQueriesForArchive = () => {
  refetchCategoryAndBlocks();
  refetchQueries([
    keys.project.all.queryKey,
    keys.categoryEvents.list._def,
    keys.actions.all.queryKey,
    keys.actions.leveragedAndCommitted.queryKey,
  ]);
};

const CategoryManagerPage = () => {
  const { pageNavigationEffect } = useAnimatePlanPages();

  const { data: categoriesList } = useCategories();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [categoryInfo, setCategoryInfo] = useState<Category>();
  const [isDropdownOpen, setDropdownOpen] = useState(true);

  const { data: projects } = useProjects({
    refetchOnMount: true,
  });

  const toast = useToast();

  const {
    isOpen: isCreateCategoryOpen,
    onOpen: onCreateCategoryOpen,
    onClose: onCreateCategoryClose,
  } = useDisclosure({ id: 'createNewCategory' });

  const updateCategory = useUpdateCategoryMutation({
    onSuccess: async (data) => {
      const isArchive = data.updateCategoryByPk.isArchived;

      const filteredProjects = projects?.project.filter((project) => project.categoryId === categoryInfo?.id);

      if (filteredProjects && filteredProjects.length > 0 && isArchive) {
        const projectsPayload = {
          categoryId: data.updateCategoryByPk.id,
          isArchived: isArchive,
        };

        await fetchData<{ isArchived: boolean; categoryId: string }>(ARCHIVE_PROJECT, projectsPayload);
      }

      refetchQueriesForArchive();

      toast({
        title: `Category ${isArchive ? 'archived' : 'restored'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setCategoryInfo(undefined);
      setOpenArchiveModal(false);
    },
  });

  const archiveActiveCategory = useCallback(async () => {
    if (!categoryInfo) return;

    updateCategory.mutateAsync({
      ...cleanCategoryForMutation(categoryInfo),
      isArchived: !categoryInfo.isArchived,
    });
  }, [updateCategory]);

  const deleteCategory = useDeleteCategory({
    onSuccess: () => {
      refetchCategoryAndBlocks();

      invalidateQueries([keys.project.all.queryKey, keys.categoryEvents.list._def]);

      toast({
        title: 'Category deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setCategoryInfo(undefined);
      setOpenDeleteModal(false);
    },
  });

  const onDeleteCategory = useCallback(async () => {
    if (!categoryInfo) return;

    deleteCategory.mutateAsync({
      categoryId: categoryInfo.id,
    });
  }, [deleteCategory]);

  const onClose = () => {
    setCategoryInfo(undefined);
    setIsEdit(false);
    onCreateCategoryClose();
  };

  useEffect(() => {
    categoryInfo && isEdit && onCreateCategoryOpen();
  }, [categoryInfo]);

  const rightSectionElements = (
    <Button
      height="full"
      marginLeft="auto"
      padding={rem(8)}
      borderWidth={rem(1)}
      borderStyle="solid"
      borderColor="stroke-primary"
      borderRadius={rem(4)}
      backgroundColor="background-primary"
      leftIcon={<Icon as={IconPlus} color="text-primary" />}
      onClick={() => {
        onCreateCategoryOpen();
        trackCreateCategorySelected('category manager');
      }}
      variant="secondary"
    >
      <Text textStyle="small" color="text-primary" fontWeight={900}>
        Create New Category
      </Text>
    </Button>
  );

  const [activeCategories, archiveCategories] = useMemo(() => {
    const active = categoriesList?.category?.filter((c) => !c.isArchived) || [];
    const archive = categoriesList?.category?.filter((c) => c.isArchived) || [];
    return [active, archive];
  }, [categoriesList]);

  return (
    <DashboardLayout pageTitle="Category Manager" rightSectionElements={rightSectionElements}>
      <motion.div
        className="row"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageNavigationEffect}
        transition={pageTransition}
      >
        {/* Active Categories Section */}
        <Box
          overflowY="auto"
          width="full"
          minHeight="42vh"
          maxHeight={{ base: '300px', md: '400px', lg: '550px' }}
          borderRadius="md"
        >
          <Grid
            alignItems={activeCategories.length === 0 ? 'center' : 'start'}
            justifyContent={activeCategories.length === 0 ? 'center' : 'start'}
            gridRowGap={rem(16)}
            gridColumnGap={rem(16)}
            gridTemplateColumns={activeCategories.length === 0 ? '1fr' : 'repeat(auto-fill, minmax(400px, 1fr))'}
            width="full"
            paddingBottom={rem(32)}
          >
            {activeCategories.length > 0 ? (
              activeCategories.map((category) => (
                <CategoryManagerOverviewCard
                  key={category.id}
                  category={category}
                  setOpenDeleteModal={() => {
                    setCategoryInfo(category);
                    setOpenDeleteModal(true);
                  }}
                  setOpenArchiveModal={() => {
                    setCategoryInfo(category);
                    setOpenArchiveModal(true);
                  }}
                  setOpenEditModal={() => {
                    setCategoryInfo(category);
                    setIsEdit(true);
                  }}
                />
              ))
            ) : (
              <Text color="gray.500" fontSize="lg" textAlign="center">
                No active categories found
              </Text>
            )}
          </Grid>
        </Box>

        {/* Archive Categories Dropdown Section */}
        <Flex
          alignItems="center"
          gap={2}
          width="full"
          background="background-tertiary"
          borderRadius="md"
          cursor="pointer"
          onClick={() => setDropdownOpen(!isDropdownOpen)}
          paddingX={4}
          paddingY={3}
        >
          <Text color="text-primary" fontWeight="800">
            Archived Categories
          </Text>
          <Icon
            as={isDropdownOpen ? IconChevronDown : IconChevronRight}
            width={rem(14)}
            height={rem(14)}
            color="text-primary"
          />
        </Flex>

        {/* Collapse for Archive Categories */}
        <Collapse animateOpacity in={isDropdownOpen}>
          <Grid
            alignItems={archiveCategories.length === 0 ? 'center' : 'start'}
            justifyContent={archiveCategories.length === 0 ? 'center' : 'start'}
            gridRowGap={rem(16)}
            gridColumnGap={rem(16)}
            gridTemplateColumns={archiveCategories.length === 0 ? '1fr' : 'repeat(auto-fill, minmax(400px, 1fr))'}
            width="full"
            marginY={6}
          >
            {archiveCategories.length > 0 ? (
              archiveCategories.map((category) => (
                <CategoryManagerOverviewCard
                  key={category.id}
                  category={category}
                  setOpenDeleteModal={() => {
                    setCategoryInfo(category);
                    setOpenDeleteModal(true);
                  }}
                  setOpenArchiveModal={() => {
                    setCategoryInfo(category);
                    setOpenArchiveModal(true);
                  }}
                  setOpenEditModal={() => {
                    setCategoryInfo(category);
                    setIsEdit(true);
                  }}
                />
              ))
            ) : (
              <Text color="gray.500" fontSize="lg" textAlign="center">
                No archived categories found
              </Text>
            )}
          </Grid>
        </Collapse>
      </motion.div>

      {/* Create/Edit Category Modal */}
      <CreateEditCategoryModal
        category={categoryInfo}
        isOpen={isCreateCategoryOpen}
        onClose={onClose}
        showEntityModalOnCreate
        isEditMode={!!categoryInfo}
      />

      {/* Delete Category Modal */}
      {openDeleteModal && (
        <DeleteArchiveCategoryModal
          isOpen={openDeleteModal}
          title="Delete Category"
          message="Are you sure you want to delete this category?"
          confirmText="Delete Category"
          cancelText="Cancel"
          confirmVariant="danger"
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={onDeleteCategory}
        />
      )}

      {/* Archive/Restore Category Modal */}
      {openArchiveModal && (
        <DeleteArchiveCategoryModal
          isOpen={openArchiveModal}
          title={`${categoryInfo?.isArchived ? 'Restore' : 'Archive'} Category`}
          message={
            categoryInfo?.isArchived
              ? CATEGORY_RESTORE_CONFIRMATION_MODAL_COPY
              : CATEGORY_ARCHIVE_CONFIRMATION_MODAL_COPY
          }
          confirmText={`${categoryInfo?.isArchived ? 'Restore' : 'Archive'} Category`}
          cancelText="Cancel"
          confirmVariant="primary"
          onCancel={() => {
            setOpenArchiveModal(false);
            setCategoryInfo(undefined);
          }}
          onConfirm={archiveActiveCategory}
        />
      )}
    </DashboardLayout>
  );
};

export default CategoryManagerPage;
