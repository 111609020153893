import { RoutesList } from '@/routes/routesList';

export const secondaryNavObj = [
  {
    label: 'Weekly Capture',
    href: RoutesList.WeeklyCapturePage,
    showSecondaryNavBar: true,
    showWeekNavigator: true,
    showCompleteWeekButton: true,
  },
  {
    label: 'Weekly Plan',
    href: RoutesList.MyPlanPage,
    showSecondaryNavBar: true,
    showWeekNavigator: true,
    showCompleteWeekButton: true,
  },
  {
    label: 'Weekly Reflection',
    href: RoutesList.CelebratePage,
    showSecondaryNavBar: true,
    showWeekNavigator: true,
    showCompleteWeekButton: false,
  },
  {
    label: 'Daily Plan',
    href: RoutesList.MyPlanDailyPage,
    showSecondaryNavBar: true,
    showWeekNavigator: true,
    showCompleteWeekButton: true,
  },
  {
    label: 'Active Projects',
    href: RoutesList.ProjectsPage,
    showSecondaryNavBar: true,
    showWeekNavigator: false,
    showCompleteWeekButton: false,
  },
  {
    label: 'Active Categories',
    href: RoutesList.CategoryManagerPage,
    showSecondaryNavBar: true,
    showWeekNavigator: false,
    showCompleteWeekButton: false,
  },
];

export const PLAN_SCHEDULE_PAGE_INDEX = [
  {
    value: 0,
    label: 'Plan',
  },
  {
    value: 1,
    label: 'Schedule',
  },
];
