import rem from '@/utils/rem';
import { Button, Flex, StyleProps, Text, chakra, shouldForwardProp } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Children, forwardRef, useCallback, useImperativeHandle, useState } from 'react';

type SegmentedControlOptionsProps = {
  value: number;
  label: string | JSX.Element;
} | null;

type SegmentedControlProps = {
  onChange: (value: number) => void;
  options: SegmentedControlOptionsProps[];
  startIndex?: number;
  disabled?: boolean;
  height?: StyleProps['height'];
  innerHeight?: StyleProps['height'];
  marginTop?: StyleProps['marginTop'];
  buttonPaddingX?: StyleProps['paddingX'];
  fullWidth?: boolean;
  layoutId: string;
};

const MotionDiv = chakra(motion.div, {
  shouldForwardProp: (prop) => shouldForwardProp(prop),
});

const DynamicSegmentedButton = forwardRef(
  (
    {
      onChange,
      options,
      disabled = false,
      startIndex = 0,
      height = rem(28),
      innerHeight = rem(22),
      buttonPaddingX = rem(10),
      marginTop = rem(0),
      layoutId,
      fullWidth = false,
    }: SegmentedControlProps,
    ref,
  ) => {
    const [activeItem, setActiveItem] = useState(startIndex);

    const onHandleSegmentedButtonSelection = useCallback(
      (el: number) => {
        setActiveItem(el);
        onChange(el);
      },
      [onChange],
    );

    useImperativeHandle(ref, () => ({
      onHandleSegmentedButtonSelection,
    }));

    return (
      <Flex
        position="relative"
        zIndex={0}
        alignItems="center"
        justifyContent="center"
        gap={rem(2)}
        overflow="hidden"
        height={height}
        padding={rem(2)}
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="stroke-primary"
        borderRadius="4"
        backgroundColor="background-primary"
      >
        {Children.toArray(
          options?.map((item) => {
            if (!item) {
              return;
            }

            const isActive = item.value === activeItem;

            const containerProps = fullWidth
              ? {
                  dislay: 'flex', // chakra doesn't have the "flex:" property
                  flex: 1,
                  flexFlow: 'row nowrap',
                  style: { display: 'flex' },
                  alignItems: 'center',
                }
              : {};

            return (
              <MotionDiv height={height} {...containerProps}>
                <Button
                  position="relative"
                  flex={fullWidth ? 1 : 'auto'}
                  height={innerHeight}
                  marginTop={marginTop}
                  color="text-primary"
                  border="none"
                  _hover={{ cursor: 'pointer' }}
                  _active={{ background: 'transparent' }}
                  _disabled={{
                    cursor: 'default',
                  }}
                  outline="none"
                  backgroundColor="transparent"
                  isDisabled={disabled}
                  onClick={() => {
                    onHandleSegmentedButtonSelection(item.value);
                  }}
                  paddingX={buttonPaddingX}
                  type="button"
                  variant={isActive ? 'solid' : 'ghost'}
                >
                  {isActive && (
                    <MotionDiv
                      layoutId={layoutId}
                      position="absolute"
                      top="0"
                      right="0"
                      bottom="0"
                      left="0"
                      zIndex="1"
                      backgroundColor="background-secondary"
                      borderRadius={rem(2)}
                    />
                  )}
                  <Text
                    as="span"
                    sx={{
                      '@media print': {
                        opacity: isActive ? '1' : '0.3',
                      },
                    }}
                    textStyle="small"
                    zIndex="2"
                    color="text-primary"
                    fontFamily="Satoshi"
                    fontWeight="700"
                  >
                    {item.label}
                  </Text>
                </Button>
              </MotionDiv>
            );
          }),
        )}
      </Flex>
    );
  },
);

export default DynamicSegmentedButton;
