import EventDetailModal from '@/components/EventDetailModal';
import IconProgressState from '@/components/IconProgressState';
import PreferredButton from '@/components/PreferredButton';
import { COMPLETE } from '@/constants/action';
import { useSoundEffects } from '@/contexts/SoundEffects';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { useUpdateAction } from '@/services/action/hooks';
import { IconClose } from '@/theme/icons';
import { ProgressStatus } from '@/types/actions';
import { ActionEventType, ActionType } from '@/types/myPlan';
import { calendarAdapterApi, formatDateToString } from '@/utils/calendar';
import { getCalenderItemGradient, getColorFromToken } from '@/utils/color';
import { convertToMinutes } from '@/utils/events';
import { humanDuration } from '@/utils/index';
import rem from '@/utils/rem';
import { trackActionProgress, trackActionStarred } from '@/utils/tracking';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  StackProps,
  Text,
  Tooltip,
  VStack,
  useBoolean,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { format, isBefore, parseISO, startOfToday } from 'date-fns';
import { isNull, isUndefined } from 'lodash';
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VscLock } from 'react-icons/vsc';

import ActionBadge from './ActionBadge';

type Props = {
  event: ActionEventType;
  onRemoveTime?: (dateWithoutTime: string) => void;
  onUnplan?: (eventId: string) => void;
  onClickEvent: (action: ActionType) => void;
};

function WeeklyEventItem({ event, onRemoveTime, onUnplan, onClickEvent, ...rest }: Props & StackProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [enableTimeSelector, setEnableTimeSelector] = useState(false);
  const [isOver, setIsOver] = useBoolean(false);
  const isCompleted = event?.action?.progressStatus === COMPLETE;
  const isArchived = event.action.project?.isArchived || event.action.category.isArchived;
  // ? Use parseISO here, otherwise date would be based on local timezone
  const dateIsBeforeToday =
    event.action.scheduledDate && isBefore(parseISO(event.action.scheduledDate), startOfToday());
  const isStarred = !isNull(event?.action?.dateOfStarring);
  const isExternalEvent = event?.action?.isExternal ?? false;
  const categoryColor = event?.action?.category?.color;
  const isDarkMode = useIsDarkMode();

  const bgGradient = useMemo(
    () => getCalenderItemGradient(categoryColor, isStarred, isDarkMode, isExternalEvent),
    [isStarred, categoryColor, isDarkMode, isExternalEvent],
  );

  const {
    isOpen: isOpenEventDetailModal,
    onOpen: onOpenEventDetailModal,
    onClose: onCloseEventDetailModal,
  } = useDisclosure();

  const { playCompleteAudio } = useSoundEffects();

  const handleRemoveTime = useCallback(() => {
    if (!selectedTime) {
      setIsOver.off();
      return;
    }

    setSelectedTime(null);
    setEnableTimeSelector(false);

    setIsOver.off();

    onRemoveTime && onRemoveTime(formatDateToString(selectedTime));
  }, [onRemoveTime, selectedTime, setIsOver]);

  const updateActionByPk = useUpdateAction();

  const handleStarClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!event?.action?.id) return;

      if (isArchived) return;

      e.stopPropagation();

      if (!event.action.dateOfStarring) {
        // action is about to be starred in the below mutation
        trackActionStarred();
      }

      updateActionByPk.mutate({
        actionId: event.action.id,
        set: {
          dateOfStarring: typeof event.action?.dateOfStarring === 'string' ? null : new Date(),
        },
      });
    },
    [event?.action?.dateOfStarring, event?.action?.id, updateActionByPk],
  );

  const handleClickProgressStatus = useCallback(
    (progressStatus: ProgressStatus) => {
      if (!event?.action?.id) return;

      if (isArchived) return;

      updateActionByPk.mutate({
        actionId: event?.action?.id,
        set: {
          progressStatus,
        },
      });

      event?.action && trackActionProgress(progressStatus, event?.action);

      if (progressStatus === 'complete') {
        playCompleteAudio();
      }
    },

    [updateActionByPk, event?.action, playCompleteAudio],
  );

  const handleActionClick = useCallback(() => {
    if (isCompleted || isArchived) {
      return;
    }

    if (isExternalEvent) {
      onOpenEventDetailModal();
      return;
    }

    onClickEvent({
      ...event.action,
      event: { id: event.id },
    });
  }, [event.action, event.id, isCompleted, isArchived, isExternalEvent, onClickEvent, onOpenEventDetailModal]);

  useOutsideClick({
    ref: buttonRef,
    handler: () => setEnableTimeSelector(false),
  });

  useEffect(() => {
    if (event?.action?.scheduledDate && event?.action?.scheduledTime) {
      const date = new Date(`${event.action.scheduledDate} ${event.action.scheduledTime} UTC`);
      setSelectedTime(date);
    }

    if (event?.action?.scheduledDate && !event?.action?.scheduledTime) {
      setSelectedTime(null);
    }
  }, [event?.action?.scheduledDate, event?.action?.scheduledTime]);

  useEffect(() => {
    if (!enableTimeSelector) {
      return;
    }

    const element = document.getElementsByClassName('react-datepicker__header--time')[0];
    element?.addEventListener('click', handleRemoveTime);

    return () => document.removeEventListener('click', handleRemoveTime);
  }, [handleRemoveTime, enableTimeSelector]);

  return (
    <VStack
      sx={{
        '@media print': {
          bgGradient: 'unset',
          backgroundColor: `print-background`,
        },
      }}
      position="relative"
      justifyContent="space-between"
      width="full"
      height={rem(90)}
      padding={rem(8)}
      bgGradient={bgGradient}
      borderRadius={rem(4)}
      _after={{
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: 0,
        backgroundColor: isDarkMode ? 'gray.950' : 'gray.50',
        height: '100%',
        width: '100%',
        zIndex: -1,
      }}
      cursor={isCompleted || isArchived ? 'default' : 'inherit'}
      box-shadow="2px 2px 9px 0px rgba(0, 0, 0, 0.25)"
      css={{
        touchAction: 'none',
      }}
      onMouseEnter={setIsOver.on}
      onMouseLeave={setIsOver.off}
      {...rest}
    >
      <Button
        width="full"
        height="full"
        margin={0}
        padding={0}
        cursor={isCompleted || isArchived ? 'default' : 'inherit'}
        __css={{
          unset: 'all',
        }}
        onClick={handleActionClick}
      >
        <VStack width="full" height="full">
          <VStack width="full" height="full">
            <HStack alignItems="center" justifyContent="space-between" width="full">
              <Flex alignItems="center" flex={1} overflow="hidden" width="full" textAlign="left">
                {isExternalEvent && event?.action?.icon && (
                  <Icon as={event?.action?.icon} boxSize={rem(10)} marginRight={rem(5)} />
                )}
                <Tooltip label={event?.action?.title} placement="top">
                  <Text
                    as="span"
                    sx={{
                      '@media print': {
                        overflow: 'hidden !important',
                      },
                    }}
                    textStyle="small"
                    width="full"
                    color="text-primary"
                    opacity={dateIsBeforeToday ? '0.5' : 1}
                    whiteSpace="normal"
                    wordBreak="break-word"
                    noOfLines={2}
                  >
                    {event?.action?.title}
                  </Text>
                </Tooltip>
              </Flex>
            </HStack>
            {event?.action?.isLocked && (
              <Flex justifyContent="flex-end" width="full">
                <Icon as={VscLock} boxSize={rem(12)} />
              </Flex>
            )}
          </VStack>
          {!isExternalEvent && !isUndefined(event?.action) && (
            <HStack
              sx={{
                containerType: 'inline-size',
                containerName: 'footer',
              }}
              alignItems="center"
              width="full"
            >
              <IconProgressState
                state={event.action.progressStatus}
                onClick={handleClickProgressStatus}
                dimension={rem(14)}
                as={Box}
                isDisabled={isArchived}
                preUpdate={false}
              />

              {event?.action?.duration && (
                <ActionBadge
                  sx={{
                    [`@container footer (min-width: ${rem(90)})`]: {
                      '&': { display: 'inline-block' },
                    },
                  }}
                  display="none"
                  backgroundColor={getColorFromToken(categoryColor, '0.10')}
                  opacity={dateIsBeforeToday ? '0.5' : 1}
                >
                  {humanDuration(convertToMinutes(event?.action?.duration) * 60)}
                </ActionBadge>
              )}
              {selectedTime && (
                <ActionBadge
                  sx={{
                    [`@container footer (min-width: ${rem(200)})`]: {
                      '&': { display: 'inline-block' },
                    },
                  }}
                  display="none"
                  backgroundColor={getColorFromToken(categoryColor, '0.10')}
                  opacity={dateIsBeforeToday ? '0.5' : 1}
                >
                  {format(selectedTime, 'h:mm a')}
                </ActionBadge>
              )}
              <PreferredButton
                readOnly={isArchived}
                isStarred={isStarred}
                onClick={handleStarClick}
                opacity={dateIsBeforeToday ? '0.5' : 1}
                sx={{
                  [`@container footer (max-width: ${rem(90)})`]: {
                    '&': { marginLeft: rem(10) },
                  },
                }}
                as={Box}
                iconColor={isDarkMode ? 'white.500' : 'gray.500'}
              />
            </HStack>
          )}
        </VStack>
      </Button>

      {onUnplan && (
        <Button
          position="absolute"
          top={rem(-8)}
          left={rem(-8)}
          alignItems="center"
          justifyContent="center"
          display={isOver && !isExternalEvent ? 'flex' : 'none'}
          width={rem(16)}
          minWidth={rem(16)}
          height={rem(16)}
          textColor="text-primary"
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="currentColor"
          borderRadius="full"
          cursor="pointer"
          backgroundColor="background-primary"
          onClick={() => onUnplan(event.id)}
          variant="unstyled"
        >
          <Icon as={IconClose} boxSize={rem(12)} />
        </Button>
      )}

      {isOpenEventDetailModal && (
        <EventDetailModal
          event={calendarAdapterApi(event.action)}
          isOpen={isOpenEventDetailModal}
          title={event.action.title}
          onClose={onCloseEventDetailModal}
        />
      )}
    </VStack>
  );
}

export default WeeklyEventItem;
