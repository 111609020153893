import ActionRow from '@/components/ActionRow';
import CaptureList from '@/components/CaptureList';
import DialogModal from '@/components/DialogModal';
import StyledModal from '@/components/StyledModal';
import { refetchQueriesForArchive } from '@/pages/CategoryManager';
import { useDeleteBulkActions } from '@/services/action/hooks';
import { Action } from '@/types/actions';
import { getTotalDurationOfActions, getTotalDurationOfPreferredActions } from '@/utils/action';
import { Box, Button, ListItem, ModalBody, ModalFooter, Text, UnorderedList, VStack, useToast } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

type ItemsToComplete = {
  component: React.ReactNode;
}[];

type CompleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  actions: Action[];
};

const DeleteBulkActionsModal = ({ isOpen, onClose, actions }: CompleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [numberOfBlockToDelete, setNumberOfBlockToDelete] = useState<{ count: number; blockNames: string[] }>({
    count: 0,
    blockNames: [],
  });

  const category = actions[0].category;

  const [selectedActions, setSelectedActions] = useState<string[]>([]);

  const { mutate: deleteBulkActions } = useDeleteBulkActions({
    onSuccess: () => {
      refetchQueriesForArchive();
      setShowConfirmMessage(false);
      onClose();
      setIsLoading(false);

      toast({
        title: 'Actions deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleToggle = (actionId: string) => {
    setSelectedActions((prev) =>
      prev.includes(actionId) ? prev.filter((id) => id !== actionId) : [...prev, actionId],
    );
  };

  const filteredActions = actions.filter(
    (a) => !a.category.isArchived && !a.project?.isArchived && a.progressStatus !== 'complete',
  );
  const filteredActionsToCompareInBlocks = actions.filter((a) => !a.category.isArchived && !a.project?.isArchived);

  const countBlocksToDelete = (actions: Action[], selectedActionIds: string[]) => {
    const actionsWithBlock = actions.filter((action) => action.blockId !== null && action.blockId !== undefined);

    const blocks = actionsWithBlock.reduce(
      (acc: any, action) => {
        const blockId = action.blockId;
        if (!blockId) return;
        if (!acc[blockId]) acc[blockId] = [];
        acc[blockId].push(action);
        return acc;
      },
      {} as Record<string, Action[]>,
    );

    let blocksToDelete = 0;
    let blockNamesToDelete: string[] = [];

    Object.entries(blocks).forEach(([blockId, actions]: any) => {
      const allSelected = actions.every((action: Action) => selectedActionIds.includes(action.id));

      if (allSelected) {
        blocksToDelete++;
        const blockName = actions[0].block.result;
        if (blockName) {
          blockNamesToDelete.push(blockName);
        }
      }
    });

    return {
      count: blocksToDelete,
      blockNames: blockNamesToDelete,
    };
  };

  const openDialogModal = () => {
    setNumberOfBlockToDelete(countBlocksToDelete(filteredActionsToCompareInBlocks, selectedActions));
    setShowConfirmMessage(true);
  };

  const handleDeleteActions = () => {
    deleteBulkActions({
      ids: selectedActions,
    });
    setIsLoading(true);
  };

  const itemsToComplete = useMemo(() => {
    const items: ItemsToComplete = [];
    items.push({
      component: (
        <CaptureList
          key={category.id}
          category={category}
          totalDurationOfIncompleteActions={getTotalDurationOfActions(filteredActions)}
          totalDurationOfPreferredIncompleteActions={getTotalDurationOfPreferredActions(filteredActions)}
          hideAccordion
        >
          <VStack>
            {filteredActions.map((action) => (
              <ActionRow
                key={action.id}
                action={action}
                isDeleteMode
                isSelected={selectedActions.includes(action.id)}
                onToggle={() => handleToggle(action.id)}
              />
            ))}
          </VStack>
        </CaptureList>
      ),
    });
    return items;
  }, [actions, selectedActions]);

  const itemActive = itemsToComplete[0];

  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <StyledModal
      contentProps={{ maxHeight: '87vh' }}
      isOpen={isOpen}
      onClose={onCloseModal}
      size="4xl"
      title="Delete actions"
    >
      <>
        <ModalBody overflow="auto">
          <Box>{itemActive?.component}</Box>
        </ModalBody>
        <ModalFooter>
          <>
            <Button onClick={onCloseModal} size="lg" variant="secondary">
              Cancel
            </Button>

            <Button
              color="white.1000"
              isDisabled={selectedActions.length === 0}
              isLoading={isLoading}
              onClick={openDialogModal}
              size="lg"
              variant="danger"
            >
              Delete selected actions
            </Button>
          </>
        </ModalFooter>
        {showConfirmMessage && (
          <DialogModal
            isOpen={showConfirmMessage}
            onCancel={() => setShowConfirmMessage(false)}
            message={`Are you sure you want to delete ${selectedActions.length > 1 ? 'these' : 'this'} action${selectedActions.length > 1 ? 's' : ''}?`}
            title="Confirm"
            onConfirm={handleDeleteActions}
            confirmText="Confirm"
            confirmVariant="danger"
            messageSize="large"
          >
            <Box>
              {numberOfBlockToDelete?.count > 0 && (
                <Box>
                  <Text fontSize="large" fontWeight={500}>
                    Doing this will also delete {numberOfBlockToDelete.count}{' '}
                    {numberOfBlockToDelete.count === 1 ? 'block' : 'blocks'},{' '}
                    {numberOfBlockToDelete.count === 1 ? 'this is' : 'these are'}:
                  </Text>
                  <UnorderedList marginTop={2}>
                    {numberOfBlockToDelete.blockNames.map((blockName, index) => (
                      <ListItem key={index} fontWeight="bold">
                        • {blockName}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              )}
            </Box>
          </DialogModal>
        )}
      </>
    </StyledModal>
  );
};

export default DeleteBulkActionsModal;
