import CreateBlockModal from '@/components/CreateBlockModal';
import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import CreateEntityModal, { Entity } from '@/components/CreateEntityButton/CreateEntityModal';
import ManagePersonModal from '@/components/ManagePersonModal';
import ManageProjectModal from '@/components/ManageProjectModal';
import { CreateProjectPayload } from '@/gql/project/types';
import { RoutesList } from '@/routes/routesList';
import { useCategories } from '@/services/categories/hooks';
import { useCreateProject } from '@/services/project/hooks';
import { useActionModal } from '@/stores/useActionModal';
import { IconPlus } from '@/theme/icons';
import rem from '@/utils/rem';
import { trackCreateCategorySelected, trackCreateEntitySelected } from '@/utils/tracking';
import { Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type CreateEntityButtonProps = {
  onClick?: () => void;
};

const CreateEntityButton = ({ onClick }: CreateEntityButtonProps) => {
  // Temporarily removed block via RRI-2907, refactor after June 30
  // const entityTypes: string[] = ['Action', 'Block', 'Project', 'Category', 'Person'];
  const entityTypes: string[] = ['Action', 'Project', 'Category', 'Person'];

  const [selectedEntityType, setSelectedEntityType] = useState('');
  const [entity, setEntity] = useState<Entity | null>(null);

  const { data: categories } = useCategories();
  const { mutateAsync: createProject } = useCreateProject();
  const { setActionModalOpen } = useActionModal();

  const { isOpen: isEntityModalOpen, onOpen: onEntityModalOpen, onClose: onEntityModalClose } = useDisclosure();

  const onClose = useCallback(() => {
    setSelectedEntityType('');
  }, []);

  const onEntityCreation = useCallback(
    (entity?: Entity) => {
      onEntityModalOpen();
      // For some entities we don't want to show the confirm modal
      if (entity) setEntity(entity);
      onClose();
    },
    [onEntityModalOpen, setEntity, onClose],
  );

  const onCreateProject = useCallback(
    async ({ name, purpose, result, categoryId }: Omit<CreateProjectPayload, 'id' | 'imageUrl'>) => {
      const id = uuidv4();

      const category = categories?.category.find((c) => c.id === categoryId);

      await createProject({
        id,
        name,
        categoryId,
        result,
        purpose,
        imageUrl: category?.selectedImageUrl ?? '',
        isArchived: false,
      });

      onEntityCreation({ name, id, slug: `/projects/${id}`, type: 'Project' });
    },
    [categories?.category, createProject, onEntityCreation],
  );

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          width={{ base: 'auto', '2xl': rem(110) }}
          height={rem(36)}
          color="text-primary"
          backgroundColor="background-secondary"
          onClick={onClick}
        >
          Create
        </MenuButton>
        <MenuList
          zIndex={5}
          width={rem(153)}
          minWidth={rem(153)}
          padding={rem(8)}
          color="white"
          border={`${rem(1)} solid`}
          borderColor="gray.700"
          borderRadius={rem(8)}
          backgroundColor="background-primary"
        >
          {entityTypes.map((entityType) => (
            <MenuItem
              key={entityType}
              gap={rem(8)}
              color="text-primary"
              fontWeight={500}
              borderRadius={rem(8)}
              _hover={{ backgroundColor: 'background-secondary' }}
              backgroundColor="transparent"
              onClick={() => {
                if (entityType === 'Action') {
                  trackCreateEntitySelected(entityType);
                  setActionModalOpen(true, {
                    location: 'navigation menu',
                    onCreateAction: () => {
                      onEntityCreation();
                    },
                  });
                } else {
                  trackCreateEntitySelected(entityType);
                  setSelectedEntityType(entityType);

                  if (entityType === 'Category') {
                    trackCreateCategorySelected('navigation menu');
                  }
                }
              }}
            >
              <IconPlus width={14} height={14} />
              {entityType}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <CreateBlockModal
        onClose={onClose}
        isOpen={selectedEntityType === 'Block'}
        categoryId={categories?.category[0]?.id}
        onSuccess={(block) => {
          onEntityCreation({
            name: block.result,
            id: block.id,
            slug: `${RoutesList.CategoryManagerPage}/${block.categoryId}`,
            type: 'Block',
          });
        }}
      />

      <ManageProjectModal
        isLoading={false}
        isOpen={selectedEntityType === 'Project'}
        onSubmit={onCreateProject}
        onClose={onClose}
      />

      <CreateEditCategoryModal onClose={onClose} isOpen={selectedEntityType === 'Category'} showEntityModalOnCreate />

      <ManagePersonModal
        isOpen={selectedEntityType === 'Person'}
        onClose={onClose}
        onCreate={(person) =>
          onEntityCreation({
            name: person.name,
            id: person.id,
            slug: `${RoutesList.PeoplePage}/${person.id}`,
            type: 'Person',
          })
        }
      />

      {entity && <CreateEntityModal isOpen={isEntityModalOpen} onCancel={onEntityModalClose} entity={entity} />}
    </>
  );
};

export default CreateEntityButton;
