import CreateEntityModal, { Entity } from '@/components/CreateEntityButton/CreateEntityModal';
import Input from '@/components/Input';
import StyledModal from '@/components/StyledModal';
import { CATEGORIES_ICONS, CATEGORY_COLORS } from '@/constants/category';
import { INSERT_CATEGORY_ONE } from '@/gql/category';
import { CreateCategoryPayload, CreateCategoryResponse } from '@/gql/category/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { RoutesList } from '@/routes/routesList';
import { cleanCategoryForMutation } from '@/services/categories';
import { useCategories, useUpdateCategoryMutation } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import { useUser } from '@/services/user/hooks';
import { useActionsCategories } from '@/stores/useActionsCategories';
import { Category } from '@/types/category';
import { getRandomCategoryImage, refetchCategoryAndBlocks } from '@/utils/category';
import { getColorFromToken, lightenDarkenColor } from '@/utils/color';
import rem from '@/utils/rem';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { trackCategoryCreated } from '@/utils/tracking';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Icon,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Children, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const CreateCategoryFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Name requires at least 1 character' })
    .max(50, { message: 'Category name must be 50 characters or less' })
    .refine((data) => !['uncategorized', 'uncategorised', 'capture'].includes(data.toLowerCase()), {
      message: 'This category name is reserved. Please enter a new name and try again',
    })
    .refine((data) => !/^\d+$/.test(data), {
      message: 'Name cannot be a string of only numbers',
    }),
  color: z.string().min(3, { message: 'Color is required' }),
  icon: z.string().min(3, { message: 'Icon is required' }),
});

type FormData = z.infer<typeof CreateCategoryFormSchema>;

type Props = {
  category?: Category;
  isOpen: boolean;
  onClose: (category?: Category) => void;
  invalidateQueries?: () => void;
  isEditMode?: boolean;
  showEntityModalOnCreate?: boolean;
};

function CreateEditCategoryModal({
  category,
  isOpen,
  onClose,
  isEditMode = false,
  showEntityModalOnCreate = false,
}: Props) {
  const { data: user } = useUser();
  const formRef = useRef<HTMLFormElement>(null);
  const toast = useToast();
  const queryClient = useQueryClient();

  const [entity, setEntity] = useState<Entity | null>(null);

  const categories = useCategories();
  const nextOrder = useMemo(
    () => (categories.data?.category || []).reduce((acc, c) => Math.max(acc, c.order ?? 0), 0) + 1,
    [categories],
  );
  const selectedCategory = useActionsCategories((state) => state.selectedCategory);
  const updateSelectedCategory = useActionsCategories((state) => state.updateSelectedCategory);

  const { isOpen: isEntityModalOpen, onOpen: onEntityModalOpen, onClose: onEntityModalClose } = useDisclosure();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setFocus,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: category?.name || '',
      color: category?.color || '',
      icon: category?.icon || '',
    },
    resolver: zodResolver(CreateCategoryFormSchema),
    mode: 'onChange',
  });

  const characterCount = watch('name')?.trim().length;

  const { mutate: createCategory } = useMutation({
    mutationFn: (payload: CreateCategoryPayload) => fetchData<CreateCategoryResponse>(INSERT_CATEGORY_ONE, payload),

    onSuccess: async ({ insertCategoryOne }) => {
      toast({
        title: 'Category created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      updateSelectedCategory(insertCategoryOne);

      if (showEntityModalOnCreate) {
        setEntity({
          name: insertCategoryOne.name,
          id: insertCategoryOne.id,
          slug: `${RoutesList.CategoryManagerPage}/${insertCategoryOne.id}`,
          type: 'Category',
        });
        onEntityModalOpen();
      }

      invalidateQueries([keys.categories.all.queryKey]);

      // passing category here to make the newly created category available to the parent component.
      onCloseModal(insertCategoryOne);

      await queryClient.invalidateQueries({
        predicate({ queryKey }) {
          const name = queryKey[0];
          return typeof name === 'string' && name.startsWith('my-plan');
        },
      });
    },

    onError: (error: APIError) => {
      let title = error?.response?.errors?.[0]?.message;

      const code = error.response.errors[0].extensions.code;
      if (code === 'constraint-violation') {
        title = 'A category with this name was already created';
      }

      toast({
        title,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const updateCategory = useUpdateCategoryMutation({
    onSuccess: async (_, variables) => {
      refetchCategoryAndBlocks();

      invalidateQueries([keys.project.category(category?.id ?? '').queryKey]);

      if (selectedCategory?.id === variables.id) {
        updateSelectedCategory(variables);
      }

      toast({
        title: 'Category updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseModal();
    },
  });

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (isEditMode) {
        if (!category?.id) {
          return;
        }

        // run mutation and close category modal
        const updatedCategoryData = {
          ...cleanCategoryForMutation(category),
          ...data,
        } as Category;
        updatedCategoryData.id = category?.id;
        updateCategory.mutateAsync(updatedCategoryData);

        return;
      }

      if (
        user?.id &&
        data?.name?.toLocaleLowerCase() !== 'uncategorized' &&
        data?.name?.toLocaleLowerCase() !== 'capture'
      ) {
        trackCategoryCreated();
        return createCategory({
          id: uuidv4(),
          name: data.name,
          color: data.color,
          icon: data.icon,
          ninetyDayGoal: null,
          oneYearGoal: null,
          order: nextOrder,
          selectedImageUrl: getRandomCategoryImage()?.name,
          ultimatePurpose: null,
          ultimateVision: null,
          role: null,
          isArchived: false,
        });
      }

      return toast({
        title: `It is not possible to create a${data?.name.toLocaleLowerCase() === 'capture' ? '' : 'n'} “${data?.name}” category`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
    [category, createCategory, isEditMode, nextOrder, toast, updateCategory, user?.id],
  );

  const onCloseModal = useCallback(
    (category?: Category) => {
      reset();
      onClose(category);
    },
    [onClose, reset],
  );

  const submitForm = () => formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

  useEffect(() => {
    // This is needed because defaultValues are set and cached at the first render
    // On the Edit mode the category at first render is undefined
    if (isEditMode) {
      reset({
        name: category?.name || '',
        color: category?.color || '',
        icon: category?.icon || '',
      });
    }
  }, [category?.color, category?.icon, category?.name, isEditMode, reset]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('name'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <>
      <StyledModal isOpen={isOpen} onClose={onCloseModal} title={`${!isEditMode ? 'Create a new' : 'Edit'} category`}>
        <ModalBody>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <VStack justifyContent="start">
              <Controller
                name="name"
                control={control}
                render={({ field: { value, onChange, ref } }) => (
                  <Input
                    ref={ref}
                    label="Name"
                    type="text"
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    error={errors?.name?.message}
                    _hover={{
                      boxShadow: 'none',
                    }}
                    _focusVisible={{
                      boxShadow: 'none',
                      borderColor: 'cyan.400 !important',
                    }}
                    _invalid={{ boxShadow: 'none', borderBottom: '1px solid red' }}
                    formControlWidth="full"
                  />
                )}
              />
              <Text
                textStyle="small"
                width="full"
                color="text-tertiary"
                fontWeight={500}
                textAlign="left"
                textTransform="uppercase"
              >
                Up to 50 characters
              </Text>
            </VStack>

            <FormLabel
              margin={`${rem(24)} 0 ${rem(16)}`}
              color="gray.400"
              fontSize="xs"
              fontWeight="900"
              textTransform="uppercase"
            >
              Color
            </FormLabel>

            <Flex flexWrap="wrap" gap="0.25rem 0" role="radiogroup">
              {Children.toArray(
                CATEGORY_COLORS.map((color) => (
                  <Controller
                    name="color"
                    control={control}
                    render={({ field: { ref, value, onChange } }) => (
                      <Flex
                        position="relative"
                        alignItems="center"
                        justifyContent="center"
                        width={rem(32)}
                        height={rem(32)}
                        margin="0.25rem 0.8125rem 0.25rem 0"
                        border={value === color ? '4px solid' : 'none'}
                        borderColor="stroke-secondary"
                        borderRadius="full"
                        _hover={{ backgroundColor: lightenDarkenColor(getColorFromToken(color), 30) }}
                        backgroundColor={getColorFromToken(color)}
                      >
                        <Checkbox
                          ref={ref}
                          zIndex={10}
                          width="100%"
                          height="100%"
                          opacity="0"
                          isChecked={value === color}
                          onChange={() => onChange(color)}
                        />
                      </Flex>
                    )}
                  />
                )),
              )}
            </Flex>

            <Divider opacity="0.15" marginY={rem(24)} />

            <FormLabel marginBottom={rem(16)} color="gray.400" fontSize="xs" fontWeight="900" textTransform="uppercase">
              Icon
            </FormLabel>

            <HStack flexWrap="wrap">
              {Children.toArray(
                Object.entries(CATEGORIES_ICONS)
                  .filter(([slug]) => !['uncategorized', 'external'].includes(slug))
                  .map(([slug, icon]) => (
                    <Controller
                      name="icon"
                      control={control}
                      render={({ field: { ref, value, onChange } }) => (
                        <Flex
                          position="relative"
                          alignItems="center"
                          justifyContent="center"
                          width={rem(42)}
                          height={rem(42)}
                          borderRadius="full"
                          cursor="pointer"
                          backgroundColor={value === slug ? 'button-bg-primary' : 'transparent'}
                        >
                          <Checkbox
                            ref={ref}
                            zIndex={2}
                            width="full"
                            height="full"
                            opacity="0"
                            isChecked={value === slug}
                            onChange={(e) => (e.target.checked ? onChange(slug) : onChange(''))}
                          />

                          <Icon
                            as={icon}
                            position="absolute"
                            width={rem(24)}
                            height={rem(24)}
                            color={value === slug ? 'text-secondary' : 'gray.300'}
                          />
                        </Flex>
                      )}
                    />
                  )),
              )}
            </HStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => onCloseModal()} size="lg" variant="secondary">
            Cancel
          </Button>
          <Button
            isDisabled={!isDirty || !isValid || characterCount === 0}
            onClick={submitForm}
            size="lg"
            variant="primary"
          >
            {`${!isEditMode ? 'Create' : 'Update'} category`}
          </Button>
        </ModalFooter>
      </StyledModal>

      {entity && <CreateEntityModal isOpen={isEntityModalOpen} onCancel={onEntityModalClose} entity={entity} />}
    </>
  );
}

export default CreateEditCategoryModal;
