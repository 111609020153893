import ActionRow from '@/components/ActionRow';
import { ActionList, DroppableContainer } from '@/components/DragAndDrop';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import EmptyActionList from '@/components/EmptyActionList';
import { useLeveragedAndCommittedActions } from '@/services/action/hooks';
import { useUpdateCommittedActionsToOtherOrder, useUpdateLeveragedActionsToOtherOrder } from '@/services/people/hooks';
import { IconCommitPersonOutline, IconLeveragePersonOutline } from '@/theme/icons';
import { Action } from '@/types/actions';
import { getActionById } from '@/utils/action';
import { EMPTY_STATE_MIN_HEIGHT, HEADER_HEIGHT } from '@/utils/people';
import rem from '@/utils/rem';
import { Box, Text, VStack } from '@chakra-ui/react';
import {
  AutoScrollActivator,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { isEmpty, orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

function PeopleGeneralView() {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [activeItemCameFrom, setActiveItemCameFrom] = useState('');

  const { data } = useLeveragedAndCommittedActions();

  const leveragedActions = useMemo(
    () =>
      orderBy(
        data?.leveragedActions.filter((a) => !a.project?.isArchived && !a.category.isArchived),
        [(obj) => (obj?.globalLeverageOrder !== null ? obj?.globalLeverageOrder : undefined), 'createdAt'],
        ['asc', 'asc'],
      ),
    [data?.leveragedActions],
  );

  const committedActions = useMemo(
    () =>
      orderBy(
        data?.committedActions.filter((a) => !a.project?.isArchived && !a.category.isArchived),
        [(obj) => (obj?.globalCommitOrder !== null ? obj?.globalCommitOrder : undefined), 'createdAt'],
        ['asc', 'asc'],
      ),
    [data?.committedActions],
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const { mutate: updateCommittedActionsToOtherOrderMutation } = useUpdateCommittedActionsToOtherOrder();
  const { mutate: updateLeveragedActionsToOtherOrderMutation } = useUpdateLeveragedActionsToOtherOrder();

  const updateLeveragedActionsToOtherOrder = useCallback(
    (leveraged: Action[]) => {
      const mutationPayload = leveraged.map((actionLeveraged, index) => ({
        _set: {
          globalLeverageOrder: index + 1,
        },
        where: {
          id: {
            _eq: actionLeveraged.id,
          },
        },
      }));

      updateLeveragedActionsToOtherOrderMutation(mutationPayload);
    },
    [updateLeveragedActionsToOtherOrderMutation],
  );

  const updateCommittedActionsToOtherOrder = useCallback(
    (committed: Action[]) => {
      const mutationPayload = committed.map((actionCommitted, index) => ({
        _set: {
          globalCommitOrder: index + 1,
        },
        where: {
          id: {
            _eq: actionCommitted.id,
          },
        },
      }));

      updateCommittedActionsToOtherOrderMutation(mutationPayload);
    },
    [updateCommittedActionsToOtherOrderMutation],
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setActiveItemCameFrom(active?.data?.current?.containerId);
    setActiveItemIndex(active?.data?.current?.index);
  };

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      const overId = over?.id;

      if (overId == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      const activeIndex = active?.data?.current?.index;
      const overIndex = over?.data?.current?.index;

      const overContainerId = over?.data?.current?.containerId;

      if (activeItemCameFrom === 'committed' && overContainerId === 'committed') {
        updateCommittedActionsToOtherOrder(arrayMove(committedActions, activeIndex, overIndex));
      }

      if (activeItemCameFrom === 'leveraged' && overContainerId === 'leveraged') {
        updateLeveragedActionsToOtherOrder(arrayMove(leveragedActions, activeIndex, overIndex));
      }
    },
    [
      activeItemCameFrom,
      committedActions,
      leveragedActions,
      updateCommittedActionsToOtherOrder,
      updateLeveragedActionsToOtherOrder,
    ],
  );

  const onDragCancel = () => {
    setActiveId(null);
    setActiveItemCameFrom('');
    setActiveItemIndex(0);
  };

  const SectionItemsOverlay = useMemo(() => {
    if (!activeId || !leveragedActions) {
      return <></>;
    }

    if (activeItemCameFrom === 'leveraged') {
      const item = getActionById(leveragedActions, activeId.toString());

      if (!item) {
        return <></>;
      }

      return createPortal(
        <DragOverlay dropAnimation={null}>
          <ActionRow action={item} idx={activeItemIndex + 1} />
        </DragOverlay>,
        document.body,
      );
    }

    if (activeItemCameFrom === 'committed') {
      const item = getActionById(committedActions, activeId.toString());

      if (!item) {
        return <></>;
      }

      return createPortal(
        <DragOverlay dropAnimation={null}>
          <ActionRow action={item} idx={activeItemIndex + 1} />
        </DragOverlay>,
        document.body,
      );
    }
  }, [activeId, activeItemCameFrom, activeItemIndex, committedActions, leveragedActions]);

  return (
    <VStack spacing={4}>
      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
        sensors={sensors}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        autoScroll={{
          activator: AutoScrollActivator.Pointer,
        }}
      >
        <>
          <DroppableContainer id="leveraged" payload={{ containerId: 'leveraged' }} height="full" padding="1rem 0">
            <Text as="h4" textStyle="large" color="text-primary">
              Actions Leveraged To Others
            </Text>

            <Box marginTop={rem(21)}>
              <SortableContext items={leveragedActions}>
                <ActionList
                  emptyListComponent={
                    <EmptyActionList
                      minHeight={`calc(${EMPTY_STATE_MIN_HEIGHT} - ${HEADER_HEIGHT})`}
                      withIcons
                      icon={IconCommitPersonOutline}
                      title="You haven't leveraged any actions yet"
                      message="Leveraging your network by seeking support, guidance, and collaborations from those around you is a major key to success. Select a person from the left side who you want to leverage and add an action, then you'll see it here."
                    />
                  }
                  isEmpty={isEmpty(leveragedActions)}
                >
                  {leveragedActions.map((action, index) => (
                    <ActionListItem
                      key={action.id}
                      item={action}
                      index={index}
                      dndPayload={{ containerId: 'leveraged', index }}
                    />
                  ))}
                </ActionList>
              </SortableContext>
            </Box>
          </DroppableContainer>
          {SectionItemsOverlay}
        </>

        <>
          <DroppableContainer id="committed" payload={{ containerId: 'committed' }} height="full" padding="1rem 0">
            <Text as="h4" textStyle="large" color="text-primary">
              Actions I've Committed To Others
            </Text>

            <Box marginTop={rem(21)}>
              <SortableContext items={committedActions}>
                <ActionList
                  emptyListComponent={
                    <EmptyActionList
                      minHeight={`calc(${EMPTY_STATE_MIN_HEIGHT} - ${HEADER_HEIGHT})`}
                      withIcons
                      icon={IconLeveragePersonOutline}
                      title="You haven't Committed any actions to others yet"
                      message="Keeping your commitments to others is a core component of strong relationships. Select a person from the left side who you want to commit to and add an action, then you'll see it here."
                    />
                  }
                  isEmpty={isEmpty(committedActions)}
                >
                  {committedActions.map((action, index) => (
                    <ActionListItem
                      key={action.id}
                      item={action}
                      index={index}
                      dndPayload={{ containerId: 'committed', index }}
                    />
                  ))}
                </ActionList>
              </SortableContext>
            </Box>
          </DroppableContainer>
          {SectionItemsOverlay}
        </>
      </DndContext>
    </VStack>
  );
}

export default PeopleGeneralView;
