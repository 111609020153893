import { IconActionTrash, IconArrowToRight, IconSRPencilDraw, IconThreeDotsVertical } from '@/theme/icons';
import rem from '@/utils/rem';
import {
  BackgroundProps,
  BorderProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PositionProps,
  SpaceProps,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback } from 'react';

type Props = {
  onEdit?: () => void;
  onDelete: () => void;
  onArchive?: () => void;
  isUncategorized?: boolean;
  isArchived?: boolean;
  categoryId?: string;
} & SpaceProps &
  PositionProps &
  BackgroundProps &
  BorderProps;

const ThreeDotsCMButton = ({
  onEdit,
  onDelete,
  onArchive,
  isUncategorized = false,
  isArchived = false,
  categoryId,
  ...spaceProps
}: Props) => {
  const handleDelete = useCallback(() => onDelete && onDelete(), [onDelete]);
  const handleArchive = useCallback(() => onArchive && onArchive(), [onArchive]);
  const handleEdit = useCallback(() => onEdit && onEdit(), [onEdit]);

  if (isUncategorized) return <></>;

  return (
    <Menu isLazy>
      <Tooltip label="Category Menu" placement="top">
        <MenuButton
          as={IconButton}
          minWidth={rem(32)}
          height={rem(32)}
          icon={<Icon as={IconThreeDotsVertical} boxSize={rem(14)} margin="0 auto" color="text-primary" />}
          size="sm"
          variant="secondary"
          {...spaceProps}
        />
      </Tooltip>
      <MenuList
        zIndex="dropdown"
        width={rem(165)}
        minWidth="0"
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="4"
        backgroundColor="background-primary"
        paddingX={rem(8)}
      >
        <MenuItem
          textStyle="small"
          marginBottom={rem(8)}
          padding={`${rem(4)} ${rem(8)}`}
          color="text-primary"
          borderRadius="4"
          _hover={{ backgroundColor: 'background-secondary' }}
          backgroundColor="inherit"
          icon={<Icon as={IconSRPencilDraw} boxSize={rem(14)} color="text-primary" />}
          iconSpacing={rem(8)}
          onClick={handleEdit}
        >
          Edit Category
        </MenuItem>

        <MenuItem
          textStyle="small"
          marginBottom={rem(8)}
          padding={`${rem(4)} ${rem(8)}`}
          color="text-primary"
          borderRadius="4"
          _hover={{ backgroundColor: 'background-secondary' }}
          backgroundColor="inherit"
          icon={
            <Icon
              as={IconArrowToRight}
              boxSize={rem(14)}
              color="text-primary"
              transform={isArchived ? 'scaleX(-1)' : 'none'}
            />
          }
          iconSpacing={rem(8)}
          onClick={handleArchive}
        >
          {isArchived ? 'Restore Category' : 'Archive Category'}
        </MenuItem>

        <MenuItem
          textStyle="small"
          padding={`${rem(4)} ${rem(8)}`}
          color="red.500"
          borderRadius="4"
          _hover={{ backgroundColor: 'background-secondary' }}
          backgroundColor="inherit"
          icon={<Icon as={IconActionTrash} boxSize={rem(14)} />}
          iconSpacing={rem(8)}
          onClick={handleDelete}
        >
          Delete Category
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ThreeDotsCMButton;
