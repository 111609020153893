import AddActionButton from '@/components/AddActionButton';
import DetailsCard from '@/components/DetailsCard';
import { GetBoardInspirationItemResponse } from '@/gql/project/types';
import { IconMountainTop } from '@/theme/icons';
import { ImageInfo } from '@/types/inspirations';
import { InspirationalBoardItem } from '@/types/project';
import rem from '@/utils/rem';
import { Box, Flex, Grid, Icon, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { Children } from 'react';
import { MdOutlineImage, MdOutlineInsertDriveFile } from 'react-icons/md';

import ImageCard from './ImageCard';
import NoteCard from './NoteCard';

type Props = {
  color: string;
  data: InspirationalBoardItem[];
  isArchived: boolean;
  onOpenImagesModal: () => void;
  onCreateNoteModal: () => void;
  onDeleteInspirationBoardItem: (projectId: string) => void;
  onOpenImageViewer: (imageInfo: Pick<ImageInfo, 'imageUrl'>) => void;
  onEditNoteModal: (item: Pick<GetBoardInspirationItemResponse, 'id' | 'body' | 'color'>) => void;
};

function InspirationBoard({
  color,
  data,
  isArchived,
  onOpenImagesModal,
  onCreateNoteModal,
  onDeleteInspirationBoardItem,
  onOpenImageViewer,
  onEditNoteModal,
}: Props) {
  return (
    <DetailsCard
      paddingX={rem(16)}
      paddingY={rem(16)}
      color={color}
      icon={IconMountainTop}
      sectionTitle="Inspiration Board"
      flex="1"
      minWidth={0}
      rightButton={
        <Menu isLazy>
          <AddActionButton
            as={MenuButton}
            onExternalClick={() => null}
            variant="secondary"
            width={rem(28)}
            tooltipText=""
            height={rem(28)}
            isDisabled={isArchived}
            sx={{
              '> span': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          />

          <MenuList
            zIndex="dropdown"
            minWidth="0"
            borderWidth={rem(1)}
            borderStyle="solid"
            borderColor="gray.700"
            borderRadius="4"
            backgroundColor="background-primary"
            paddingX={rem(8)}
          >
            <MenuItem
              textStyle="small"
              marginBottom={rem(8)}
              padding={`${rem(4)} ${rem(8)}`}
              color="text-primary"
              _hover={{ backgroundColor: 'background-secondary' }}
              backgroundColor="inherit"
              icon={<Icon as={MdOutlineImage} boxSize={rem(14)} color="text-primary" />}
              iconSpacing={rem(8)}
              onClick={onOpenImagesModal}
            >
              Add image
            </MenuItem>
            <MenuItem
              textStyle="small"
              padding={`${rem(4)} ${rem(8)}`}
              color="text-primary"
              _hover={{ backgroundColor: 'background-secondary' }}
              backgroundColor="inherit"
              icon={<Icon as={MdOutlineInsertDriveFile} boxSize={rem(14)} color="text-primary" />}
              iconSpacing={rem(8)}
              onClick={onCreateNoteModal}
            >
              Add a quick note
            </MenuItem>
          </MenuList>
        </Menu>
      }
    >
      <Box width="full" height="full">
        {isEmpty(data) ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={rem(8)}
            width="full"
            height="full"
          >
            {isArchived ? (
              <Text textStyle="smallBlack" color="gray.400" textAlign="start">
                No Inspiration added
              </Text>
            ) : (
              <>
                <Text textStyle="smallBlack" color="text-tertiary" textTransform="uppercase">
                  Transform This Space Into Your Beacon of Inspiration
                </Text>
                <Text textStyle="medium" color="text-primary" textAlign="center">
                  Fill this space with images and notes that spark your passion, ignite your creativity, and propel you
                  towards your ultimate aspirations.
                </Text>
              </>
            )}
          </Flex>
        ) : (
          <Grid gridGap={rem(8)} gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))" width="full">
            {Children.toArray(
              data?.map((item) => {
                if (item?.mimeType === 'txt') {
                  return (
                    <NoteCard
                      isArchived={isArchived}
                      noteInfo={item}
                      onEditNoteModal={isArchived ? () => {} : onEditNoteModal}
                    />
                  );
                }

                return (
                  <ImageCard
                    isArchived={isArchived}
                    imageInfo={item}
                    onDeleteInspirationBoardItem={onDeleteInspirationBoardItem}
                    onOpenImageViewer={isArchived ? () => {} : onOpenImageViewer}
                  />
                );
              }),
            )}
          </Grid>
        )}
      </Box>
    </DetailsCard>
  );
}

export default InspirationBoard;
