import { GetBoardInspirationItemResponse } from '@/gql/project/types';
import rem from '@/utils/rem';
import { AspectRatio, Button, Flex, Text } from '@chakra-ui/react';

type Props = {
  noteInfo: {
    id: string;
    body: string | null;
    color: string | null;
  };
  onEditNoteModal: (item: Pick<GetBoardInspirationItemResponse, 'id' | 'body' | 'color'>) => void;
  isArchived?: boolean;
};

const NoteCard = ({ noteInfo, onEditNoteModal, isArchived }: Props) => {
  const [token] = (noteInfo?.color ?? 'red.300').split('.');

  return (
    <AspectRatio overflow="visible !important" maxWidth="100%" height={rem(130)} ratio={1}>
      <Button
        pointerEvents={isArchived ? 'none' : 'unset'}
        __css={{ unset: 'all' }}
        onClick={() => onEditNoteModal(noteInfo)}
        variant="unstyled"
      >
        <Flex
          position="relative"
          alignItems="start !important"
          justifyContent="flex-start !important"
          overflow="visible !important"
          width="full"
          height="full"
          padding={rem(10)}
          opacity={isArchived ? '0.3' : '1'}
          borderRadius={rem(10)}
          backgroundColor={`${token}.200`}
        >
          <Text
            color="blackAlpha.900"
            fontSize={rem(12)}
            fontWeight={400}
            lineHeight={rem(16)}
            textAlign="left"
            noOfLines={10}
          >
            {noteInfo?.body}
          </Text>
        </Flex>
      </Button>
    </AspectRatio>
  );
};
export default NoteCard;
