import DialogModal from '@/components/DialogModal';
import ManageProjectModal from '@/components/ManageProjectModal';
import { NoProjects } from '@/components/ProjectStep';
import ProjectListItem from '@/components/ProjectStep/ProjectListItem';
import {
  PROJECT_ARCHIVE_CONFIRMATION_MODAL_COPY,
  PROJECT_RESTORE_FIRST_CONFIRMATION_MODAL_COPY,
  PROJECT_RESTORE_SECOND_CONFIRMATION_MODAL_COPY,
} from '@/constants/archive';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { keys } from '@/gql/global/keys';
import DashboardLayout from '@/layouts/Dashboard';
import { refetchQueriesForArchive } from '@/pages/CategoryManager';
import { RoutesList } from '@/routes/routesList';
import { cleanCategoryForMutation } from '@/services/categories';
import { useCategories, useUpdateCategoryMutation } from '@/services/categories/hooks';
import { useCreateProject, useDeleteProject, useProjects, useUpdateProject } from '@/services/project/hooks';
import { IconChevronDown, IconChevronRight, IconPlus } from '@/theme/icons';
import { ProjectType } from '@/types/project';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { refetchQueries } from '@/utils/tanStackQuery';
import { trackProjectDeleted } from '@/utils/tracking';
import { Box, Button, Collapse, Flex, Grid, Icon, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const OPTIONS = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 2,
    label: 'Archived',
  },
];

type CreateProjectPayload = {
  name: string;
  result: string;
  purpose: string;
  categoryId: string;
};

function Project() {
  const navigate = useNavigate();

  const toast = useToast();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [projectInfo, setProjectInfo] = useState<ProjectType>();
  const [isDropdownOpen, setDropdownOpen] = useState(true);

  const { data: categories } = useCategories();

  const { data: projects, isLoading } = useProjects({
    refetchOnMount: true,
  });

  const { pageNavigationEffect } = useAnimatePlanPages();

  const {
    isOpen: isOpenCreateProjectModal,
    onOpen: openCreateProjectModal,
    onClose: onCloseCreateProjectModal,
  } = useDisclosure();

  const { mutateAsync: createProject, isLoading: isCreatingProject } = useCreateProject();
  const { mutateAsync: deleteProject } = useDeleteProject();
  const { mutateAsync: updateProject } = useUpdateProject();

  const updateCategory = useUpdateCategoryMutation();

  const onCreateProject = useCallback(
    async ({ name, purpose, result, categoryId }: CreateProjectPayload) => {
      const id = uuidv4();

      const category = categories?.category.find((c) => c.id === categoryId);

      await createProject({
        id,
        name,
        categoryId,
        result,
        purpose,
        imageUrl: category?.selectedImageUrl ?? '',
        isArchived: false,
      });

      navigate(`${RoutesList.ProjectsPage}/${id}`);
    },
    [categories?.category, createProject, navigate],
  );

  const onDeleteProject = useCallback(async () => {
    if (!projectInfo?.id) {
      return;
    }

    await deleteProject(
      { id: projectInfo.id },
      {
        onSuccess: () => {
          setOpenDeleteModal(false);
          onCloseCreateProjectModal();
          toast({
            title: 'Project deleted',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });

          trackProjectDeleted();
        },
      },
    );
  }, [deleteProject, navigate, projectInfo?.id, toast]);

  useEffect(() => {
    return () => onCloseCreateProjectModal();
  }, [onCloseCreateProjectModal]);

  useEffect(() => {
    projectInfo && isEdit && openCreateProjectModal();
  }, [projectInfo]);

  const onClose = () => {
    setProjectInfo(undefined);
    setIsEdit(false);
    onCloseCreateProjectModal();
  };

  const archiveActiveProject = useCallback(async () => {
    if (!projectInfo) return;
    await updateProject(
      {
        id: projectInfo.id,
        name: projectInfo.name,
        result: projectInfo.result,
        purpose: projectInfo.purpose,
        categoryId: projectInfo.categoryId,
        imageUrl: projectInfo.imageUrl ?? null,
        isArchived: !projectInfo.isArchived,
      },
      {
        onSuccess: async () => {
          const category = categories?.category.find((c) => c.id === projectInfo.categoryId);

          if (!category) return;

          if (category?.isArchived) {
            await updateCategory.mutateAsync({
              ...cleanCategoryForMutation(category),
              isArchived: !category.isArchived,
            });
          }

          setOpenArchiveModal(false);

          refetchQueriesForArchive();
          refetchQueries([keys.project.category(category?.id).queryKey]);

          toast({
            title: `Project ${projectInfo.isArchived ? 'restored' : 'archived'}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  }, [projectInfo]);

  const editProject = useCallback(
    async (payload: CreateProjectPayload) => {
      if (!projectInfo) return;
      await updateProject(
        {
          ...payload,
          id: projectInfo.id,
          imageUrl: projectInfo.imageUrl ?? null,
          isArchived: projectInfo.isArchived,
        },
        {
          onSuccess: () => {
            onClose();
            toast({
              title: 'Project edited',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [projectInfo],
  );

  const [activeProjects, archiveProjects] = useMemo(() => {
    if (!projects?.project) return [[], []];

    return [projects.project.filter((p) => !p.isArchived), projects.project.filter((p) => p.isArchived)];
  }, [projects]);

  if (isLoading || !projects) {
    return (
      <DashboardLayout pageTitle="Projects" paddingX={rem(16)}>
        <Flex alignItems="center" justifyContent="center" marginTop={rem(128)}>
          <Spinner />
        </Flex>
      </DashboardLayout>
    );
  }

  const hasProjects = projects.project.length > 0;

  const rightSectionElements = (
    <Button
      height="full"
      marginLeft="auto"
      padding={rem(8)}
      leftIcon={<Icon as={IconPlus} color="text-primary" />}
      onClick={openCreateProjectModal}
      variant="secondary"
    >
      <Text textStyle="small" color="text-primary" fontWeight={900}>
        Create New Project
      </Text>
    </Button>
  );

  return (
    <DashboardLayout
      pageTitle="Projects"
      paddingX={hasProjects ? rem(16) : 0}
      rightSectionElements={rightSectionElements}
    >
      <motion.div
        className="row"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageNavigationEffect}
        transition={pageTransition}
      >
        {activeProjects.length > 0 || archiveProjects.length > 0 ? (
          <>
            {/* Projects Section */}
            <Box
              overflowY="auto"
              width="full"
              minHeight="42vh"
              maxHeight={{ base: '300px', md: '400px', lg: '550px' }}
              borderRadius="md"
            >
              <Grid
                alignItems={activeProjects.length === 0 ? 'center' : 'start'}
                justifyContent={activeProjects.length === 0 ? 'center' : 'start'}
                gridRowGap={rem(16)}
                gridColumnGap={rem(16)}
                gridTemplateColumns={activeProjects.length === 0 ? '1fr' : 'repeat(auto-fill, minmax(400px, 1fr))'}
                width="full"
                paddingBottom={rem(32)}
              >
                {activeProjects.length > 0 ? (
                  activeProjects.map((project) => (
                    <ProjectListItem
                      key={project.id}
                      project={project}
                      setOpenDeleteModal={() => {
                        setProjectInfo(project);
                        setOpenDeleteModal(true);
                      }}
                      setOpenArchiveModal={() => {
                        setProjectInfo(project);
                        setOpenArchiveModal(true);
                      }}
                      setOpenEditModal={() => {
                        setProjectInfo(project);
                        setIsEdit(true);
                      }}
                    />
                  ))
                ) : (
                  <Text color="gray.500" fontSize="lg" textAlign="center">
                    No active projects found
                  </Text>
                )}
              </Grid>
            </Box>

            {/* Archive Projects Button */}
            <Flex
              alignItems="center"
              gap={2}
              width="full"
              background="background-tertiary"
              borderRadius="md"
              cursor="pointer"
              onClick={() => setDropdownOpen(!isDropdownOpen)}
              paddingX={4}
              paddingY={3}
            >
              <Text color="text-primary" fontWeight="800">
                Archived Projects
              </Text>
              <Icon
                as={isDropdownOpen ? IconChevronDown : IconChevronRight}
                width={rem(14)}
                height={rem(14)}
                color="text-primary"
              />
            </Flex>

            {/* Collapse for Archive Projects */}
            <Collapse animateOpacity in={isDropdownOpen}>
              <Grid
                alignItems={archiveProjects.length === 0 ? 'center' : 'start'}
                justifyContent={archiveProjects.length === 0 ? 'center' : 'start'}
                gridRowGap={rem(16)}
                gridColumnGap={rem(16)}
                gridTemplateColumns={archiveProjects.length === 0 ? '1fr' : 'repeat(auto-fill, minmax(400px, 1fr))'}
                width="full"
                marginY={6}
              >
                {archiveProjects.length > 0 ? (
                  archiveProjects.map((project) => (
                    <ProjectListItem
                      key={project.id}
                      project={project}
                      setOpenDeleteModal={() => {
                        setProjectInfo(project);
                        setOpenDeleteModal(true);
                      }}
                      setOpenArchiveModal={() => {
                        setProjectInfo(project);
                        setOpenArchiveModal(true);
                      }}
                      setOpenEditModal={() => {
                        setProjectInfo(project);
                        setIsEdit(true);
                      }}
                    />
                  ))
                ) : (
                  <Text color="gray.500" fontSize="lg" textAlign="center">
                    No archived projects found
                  </Text>
                )}
              </Grid>
            </Collapse>
          </>
        ) : (
          projects?.project?.length === 0 && <NoProjects onCreateProjectClick={openCreateProjectModal} />
        )}

        {/* Modals */}
        {isOpenCreateProjectModal && (
          <ManageProjectModal
            isOpen={isOpenCreateProjectModal}
            isLoading={isCreatingProject}
            onSubmit={isEdit ? editProject : onCreateProject}
            onClose={onClose}
            onDelete={onDeleteProject}
            project={projectInfo}
          />
        )}

        {openDeleteModal && (
          <DialogModal
            cancelText="Cancel"
            confirmText="Delete Project"
            confirmVariant="danger"
            isOpen={openDeleteModal}
            message="Do you really want to remove this project?"
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={onDeleteProject}
            title="Confirm"
          />
        )}

        {openArchiveModal && (
          <DialogModal
            cancelText="Cancel"
            confirmText={`${projectInfo?.isArchived ? 'Restore' : 'Archive'} Project`}
            confirmVariant="primary"
            isOpen={openArchiveModal}
            message={
              projectInfo?.isArchived
                ? categories?.category.find((c) => c.id === projectInfo.categoryId)?.isArchived
                  ? PROJECT_RESTORE_SECOND_CONFIRMATION_MODAL_COPY
                  : PROJECT_RESTORE_FIRST_CONFIRMATION_MODAL_COPY
                : PROJECT_ARCHIVE_CONFIRMATION_MODAL_COPY
            }
            onCancel={() => setOpenArchiveModal(false)}
            onConfirm={archiveActiveProject}
            title={`${projectInfo?.isArchived ? 'Restore' : 'Archive'} Project`}
          />
        )}
      </motion.div>
    </DashboardLayout>
  );
}

export default Project;
