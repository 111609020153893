import { gql } from 'graphql-request';

const CATEGORY_FRAGMENT = gql`
  fragment Category on Category {
    name
    id
    icon
    color
    selectedImageUrl
    ninetyDayGoal
    oneYearGoal
    secondaryPurposes
    selectedImageUrl
    ultimatePurpose
    ultimateVision
    role
    createdAt
    order
    isArchived
  }
`;

export const GET_CATEGORIES = gql`
  ${CATEGORY_FRAGMENT}

  query getCategories {
    category(orderBy: [{ order: DESC }, { createdAt: DESC }]) {
      ...Category
    }
  }
`;

export const INSERT_CATEGORY_ONE = gql`
  mutation insertCategoryOne(
    $color: String
    $icon: String
    $id: uuid
    $name: String
    $ninetyDayGoal: String
    $oneYearGoal: String
    $order: Int
    # $secondaryPurposes: _varchar
    $selectedImageUrl: String
    $ultimatePurpose: String
    $ultimateVision: String
    $role: String
    $isArchived: Boolean
  ) {
    insertCategoryOne(
      object: {
        id: $id
        icon: $icon
        name: $name
        color: $color
        ninetyDayGoal: $ninetyDayGoal
        oneYearGoal: $oneYearGoal
        order: $order
        # secondaryPurposes: $secondaryPurposes
        selectedImageUrl: $selectedImageUrl
        ultimatePurpose: $ultimatePurpose
        ultimateVision: $ultimateVision
        role: $role
        isArchived: $isArchived
      }
    ) {
      id
      name
      icon
      color
      createdById
      createdAt
      ninetyDayGoal
      oneYearGoal
      order
      # secondaryPurposes
      selectedImageUrl
      ultimatePurpose
      ultimateVision
      role
    }
  }
`;

export const UPDATE_CATEGORY_ONE = gql`
  mutation UpdateCategoryByPk(
    $id: uuid!
    $name: String
    $color: String
    $icon: String
    $ninetyDayGoal: String
    $oneYearGoal: String
    $secondaryPurposes: [String!]
    $selectedImageUrl: String
    $ultimatePurpose: String
    $ultimateVision: String
    $role: String
    $isArchived: Boolean
  ) {
    updateCategoryByPk(
      pkColumns: { id: $id }
      _set: {
        name: $name
        color: $color
        icon: $icon
        ninetyDayGoal: $ninetyDayGoal
        oneYearGoal: $oneYearGoal
        secondaryPurposes: $secondaryPurposes
        selectedImageUrl: $selectedImageUrl
        ultimatePurpose: $ultimatePurpose
        ultimateVision: $ultimateVision
        role: $role
        isArchived: $isArchived
      }
    ) {
      id
      name
      icon
      color
      selectedImageUrl
      createdById
      modifiedAt
      createdAt
      ninetyDayGoal
      oneYearGoal
      secondaryPurposes
      selectedImageUrl
      ultimatePurpose
      ultimateVision
      role
      isArchived
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation EmptyAndDeleteCategory($categoryId: uuid!) {
    emptyAndDeleteCategory(categoryId: $categoryId) {
      actionIds
      blockIds
    }
  }
`;

export const UPDATE_CATEGORIES_ORDER = gql`
  mutation updateCategoriesOrder($categoryUpdates: [CategoryUpdates!]!) {
    updateCategoryMany(updates: $categoryUpdates) {
      returning {
        id
        order
      }
    }
  }
`;
