import { GetBlocksResponse } from '@/gql/block/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import {
  ADD_ACTION_TO_BLOCK,
  COMPLETE_BLOCK,
  CREATE_EVENT,
  DELETE_BLOCK,
  DELETE_EVENT_AND_UPDATE_ACTION,
  DELETE_EVENT_AND_UPDATE_BLOCK_ACTION,
  INCOMPLETE_BLOCK,
  REMOVE_ACTION_FROM_BLOCK,
  UPDATE_ACTIONS_CATEGORY_ORDER,
  UPDATE_BLOCK_ACTIONS_ORDER,
} from '@/gql/myPlan';
import {
  AddActionToBlockPayload,
  AddActionToBlockResponse,
  CompleteBlockPayload,
  CompleteBlockResponse,
  CreateEventPayload,
  CreateEventResponse,
  DeleteBlockPayload,
  DeleteBlockResponse,
  DeleteEventAndUpdateBlockActionPayload,
  DeleteEventPayload,
  DeleteEventResponse,
  IncompleteBlockPayload,
  IncompleteBlockResponse,
  RemoveActionFromBlockPayload,
  RemoveActionFromBlockResponse,
  UpdateActionsCategoryOrderPayload,
  UpdateActionsCategoryOrderResponse,
  UpdateBlockActionsBlockOrderPayload,
  UpdateBlockActionsBlockOrderResponse,
} from '@/gql/myPlan/types';
import { useActions, useUpdateActionStatus } from '@/services/action/hooks';
import { useBlocks } from '@/services/block/hooks';
import { useCategories } from '@/services/categories/hooks';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { getSelectedDate } from '@/stores/useCalendar';
import { ActionResponse, isPlannedAction } from '@/types/actions';
import { Block } from '@/types/block';
import { ActionEventType } from '@/types/myPlan';
import { getActionById, refetchActions } from '@/utils/action';
import { getBlockById } from '@/utils/block';
import { formatDateToString, utcToLocalDate } from '@/utils/calendar';
import { getMonthlyCalendarQueryKey } from '@/utils/query';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { pick } from 'lodash';
import { useMemo } from 'react';

export const useActionsBlocksAndEvents = (selectedDate?: Date) => {
  const { data: actions, ...actionsQueryState } = useActions({}, selectedDate);
  const { data: blocks, ...blocksQueryState } = useBlocks();

  const filteredBlocks = blocks?.block.filter((block) => !block.project?.isArchived && !block.category.isArchived);

  const filteredActions = actions?.action.filter(
    (action) => !action.project?.isArchived && !action.project?.isArchived,
  );

  const data = useMemo(
    () => ({
      action:
        filteredActions?.map((action) => ({
          ...action,
          typeName: 'Action',
        })) ?? [],
      block: filteredBlocks ?? [],
    }),
    [actions, blocks],
  );

  return {
    isLoading: actionsQueryState.isLoading || blocksQueryState.isLoading,
    isError: actionsQueryState.isError || blocksQueryState.isError,
    error: actionsQueryState.error || blocksQueryState.error,
    data,
  };
};

export const useWeeklyPlanActionEvents = (selectedDate?: Date) => {
  const { data: myPlanData, ...queryState } = useActionsBlocksAndEvents(selectedDate);

  const { data: categoriesList } = useCategories();

  const actionEvents = useMemo(() => {
    const actionEvents: ActionEventType[] = [];

    if (!myPlanData) return actionEvents;

    const categories = categoriesList?.category ?? [];

    // Go through all categories.
    for (let category of categories) {
      for (let action of myPlanData.action) {
        // Skip actions that are not planned, as we are interested only in
        // actions with an event
        if (!isPlannedAction(action)) continue;
        if (action?.categoryId === category?.id && action?.event?.id && !action.category.isArchived) {
          actionEvents.push({
            id: action.event.id,
            action: { ...action, typeName: 'Action' },
            actionId: action.id,
            typeName: 'Event',
          });
        }
      }
    }
    return actionEvents;
  }, [categoriesList?.category, myPlanData]);

  return {
    ...queryState,
    data: actionEvents,
  };
};

export const useDailyPlanActionEvents = (weeklyPlanId: string | undefined, selectedDate: Date, view: string) => {
  const { data: weeklyPlanActionEvents, ...queryState } = useWeeklyPlanActionEvents(selectedDate);

  const dailyActionEvents = useMemo(
    () =>
      weeklyPlanActionEvents.filter((a) => {
        const eventDate = utcToLocalDate(
          a.action.scheduledDate,
          a.action.scheduledTime,
          a.action.timezone,
          a.action.gmtOffset,
        );
        return formatDateToString(eventDate) === formatDateToString(selectedDate) && a.action.scheduledTime !== null;
      }),
    [weeklyPlanActionEvents, selectedDate],
  );

  return {
    ...queryState,
    data: view === 'day' ? dailyActionEvents : weeklyPlanActionEvents,
  };
};

type UpdateActionsOrderDTO = {
  actionId: string;
  categoryOrder: number;
};

export const useUpdateActionsCategoryOrder = (
  options?: Omit<
    UseMutationOptions<UpdateActionsCategoryOrderResponse, APIError, UpdateActionsCategoryOrderPayload[]>,
    'onMutate'
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateActionsCategoryOrderPayload[]) =>
      fetchData<UpdateActionsCategoryOrderResponse>(UPDATE_ACTIONS_CATEGORY_ORDER, { actionUpdates }),
    onSuccess: () => {
      const selectedDate = getSelectedDate();

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      if (!weeklyPlanId) {
        return;
      }

      invalidateQueries([keys.actions.all._ctx.week(weeklyPlanId).queryKey]);
    },
    ...options,
  });
};

export const useUpdateBlockActionsBlockOrder = (
  options?: Omit<
    UseMutationOptions<UpdateBlockActionsBlockOrderResponse, APIError, UpdateBlockActionsBlockOrderPayload>,
    'onMutate'
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateBlockActionsBlockOrderPayload) =>
      fetchData<UpdateBlockActionsBlockOrderResponse>(UPDATE_BLOCK_ACTIONS_ORDER, {
        actionUpdates: actionUpdates.updates,
      }),

    onSuccess: (data, { categoryId, projectId }) => {
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);
      projectId && cacheKeys.push(keys.project.detail(projectId).queryKey);

      invalidateQueries(cacheKeys);
    },

    ...options,
  });
};

export const useRemoveActionFromBlock = (
  options?: Omit<UseMutationOptions<RemoveActionFromBlockResponse, APIError, RemoveActionFromBlockPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: RemoveActionFromBlockPayload) =>
      fetchData<RemoveActionFromBlockResponse>(REMOVE_ACTION_FROM_BLOCK, pick(payload, ['actionId'])),

    onSuccess: (data, { categoryId, projectId }) => {
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);
      projectId && cacheKeys.push(keys.project.detail(projectId).queryKey);

      invalidateQueries(cacheKeys);
    },

    ...options,
  });
};

export const useAddActionToBlock = (
  options?: Omit<UseMutationOptions<AddActionToBlockResponse, APIError, AddActionToBlockPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: AddActionToBlockPayload) =>
      fetchData<AddActionToBlockResponse>(
        ADD_ACTION_TO_BLOCK,
        pick(payload, ['actionId', 'blockId', 'blockOrder', 'projectId']),
      ),

    onSuccess: (_, { blockId, projectId, categoryId }) => {
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const blocks = queryClient.getQueryData<GetBlocksResponse>(keys.blocks.all.queryKey);
      const block = getBlockById(blocks?.block ?? [], blockId);

      const cacheKeys = [];
      block?.isCompleted && cacheKeys.push(keys.blocks.all.queryKey);
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);
      projectId && cacheKeys.push(keys.project.detail(projectId).queryKey);
      projectId && cacheKeys.push(keys.project.detail(projectId).queryKey);

      invalidateQueries(cacheKeys);
    },
    ...options,
  });
};

export const useCreateEvent = (
  options?: Omit<UseMutationOptions<CreateEventResponse, APIError, CreateEventPayload>, 'onMutate' | 'onSettled'>,
) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: CreateEventPayload) => fetchData<CreateEventResponse>(CREATE_EVENT, payload),
    ...options,
    onSuccess: (data, variables, context) => {
      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      if (!weeklyPlanId) {
        return;
      }

      const actions = queryClient.getQueryData<ActionResponse>(keys.actions.all._ctx.week(weeklyPlanId).queryKey);

      const action = getActionById(actions?.action ?? [], data.updateActionByPk.id);

      if (!action) {
        return;
      }
      cronofySyncEvent({ eventId: variables.actionId, eventType: 'Action' });
      refetchActions(action);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteEventAndUpdateAction = (
  options?: Omit<UseMutationOptions<DeleteEventResponse, APIError, DeleteEventPayload>, 'onMutate' | 'onSettled'>,
) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: DeleteEventPayload) =>
      fetchData<DeleteEventResponse>(DELETE_EVENT_AND_UPDATE_ACTION, payload),
    ...options,
    onSuccess: (data, variables, context) => {
      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      cacheKeys.push(getMonthlyCalendarQueryKey());

      invalidateQueries(cacheKeys);

      cronofySyncEvent({ eventId: variables.actionId, eventType: 'Action', deleted: true });
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteEventAndUpdateBlockAction = (
  options?: Omit<
    UseMutationOptions<DeleteEventResponse, APIError, DeleteEventAndUpdateBlockActionPayload>,
    'onMutate' | 'onSettled'
  >,
) => {
  const updateActionStatus = useUpdateActionStatus();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: DeleteEventAndUpdateBlockActionPayload) =>
      fetchData<DeleteEventResponse>(DELETE_EVENT_AND_UPDATE_BLOCK_ACTION, payload),

    onSettled: (data) => {
      if (data?.updateActionByPk?.id) {
        updateActionStatus.mutate({
          old: {
            id: data?.updateActionByPk?.id,
            block_id: data?.updateActionByPk?.block?.id,
          },
          new: null,
        });
      }
    },

    ...options,

    onSuccess: (data, variables, context) => {
      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      if (!weeklyPlanId) {
        return;
      }

      invalidateQueries([keys.actions.all._ctx.week(weeklyPlanId).queryKey]);
      cronofySyncEvent({ eventId: variables.actionId, eventType: 'Action', deleted: true });
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCompleteBlockStatus = (
  options?: UseMutationOptions<CompleteBlockResponse, APIError, CompleteBlockPayload>,
) => {
  return useMutation({
    mutationFn: (payload: CompleteBlockPayload) => fetchData<CompleteBlockResponse>(COMPLETE_BLOCK, payload),
    ...options,
  });
};

export const useIncompleteBlockStatus = (
  options?: UseMutationOptions<IncompleteBlockResponse, APIError, IncompleteBlockPayload>,
) => {
  return useMutation({
    mutationFn: (payload: IncompleteBlockPayload) => fetchData<IncompleteBlockResponse>(INCOMPLETE_BLOCK, payload),
    ...options,
  });
};

export const useDeleteBlock = (
  options?: Omit<UseMutationOptions<DeleteBlockResponse, APIError, DeleteBlockPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: DeleteBlockPayload) =>
      fetchData<DeleteBlockResponse>(DELETE_BLOCK, pick(payload, ['blockId'])),

    onSuccess: (_, { blockId, projectId }) => {
      const blocks = queryClient.getQueryData<GetBlocksResponse>(keys.blocks.all.queryKey);
      const block = getBlockById(blocks?.block ?? [], blockId);
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);
      const cacheKeys = [];
      cacheKeys.push(keys.blocks.all.queryKey);
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      block?.categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(block.categoryId).queryKey);
      projectId && cacheKeys.push(keys.project.detail(projectId).queryKey);
      invalidateQueries(cacheKeys);
    },

    ...options,
  });
};

export const onMutateBlockStatus = (blockId: string, isCompleted = false) => {
  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      block: state.block.map((block) => {
        if (block.id === blockId) {
          return {
            ...block,
            isCompleted,
          };
        }

        return block;
      }),
    };
  });
};

export const refetchBlocks = () => {
  const selectedDate = getSelectedDate();

  if (!selectedDate) {
    return;
  }

  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  if (!weeklyPlanId) {
    return;
  }

  invalidateQueries([keys.actions.all._ctx.week(weeklyPlanId).queryKey, keys.blocks.all.queryKey]);
};

export const completeActionsOfBlock = (categoryId?: string) => {
  const selectedDate = getSelectedDate();
  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  const cacheKeys = [];
  weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
  categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);

  invalidateQueries(cacheKeys);
};

export const removeIncompleteActionsFromBlock = (block: Block) => {
  const selectedDate = getSelectedDate();
  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  const cacheKeys = [];
  weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
  block.categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(block.categoryId).queryKey);
  block.projectId && cacheKeys.push(keys.project.detail(block.projectId).queryKey);

  invalidateQueries(cacheKeys);
};

export const deleteEventFromCache = () => {
  const selectedDate = getSelectedDate();

  if (!selectedDate) {
    return;
  }

  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  if (!weeklyPlanId) {
    return;
  }

  invalidateQueries([keys.actions.all._ctx.week(weeklyPlanId).queryKey]);
};
