import EllipsisTooltipText from '@/components/ActionRow/EllipsisTooltipText';
import DialogModal from '@/components/DialogModal';
import PreferredButton from '@/components/PreferredButton';
import ManageKeyResultModal from '@/components/ProjectDetail/ManageKeyResultModal';
import ThreeDotsKeyResultButton from '@/components/ProjectDetail/ThreeDotsKeyResultButton';
import { keys } from '@/gql/global/keys';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { useDeleteKeyResult, useUpdateKeyResult } from '@/services/project/hooks';
import { IconComplete, IconIncomplete } from '@/theme/icons';
import { KeyResult } from '@/types/project';
import { generateLinearGradient } from '@/utils/color';
import rem from '@/utils/rem';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { Flex, FlexProps, HStack, Icon, IconButton, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { format } from 'date-fns';
import { memo, useCallback } from 'react';

type Props = {
  index: number;
  keyResult: KeyResult;
  projectCategoryColor: string;
  projectId: string;
  isArchived?: boolean;
  onCreateAction: (name: string) => void;
  onCreateBlock: (name: string) => void;
  onOpen: () => void;
  onClose: () => void;
};

function KeyResultItem({
  index,
  keyResult,
  projectCategoryColor,
  projectId,
  isArchived,
  onCreateAction,
  onCreateBlock,
  onOpen,
  onClose,
}: Props & FlexProps) {
  const { id: keyId, name, dueDate, isCompleted, isStarred } = keyResult;
  const toast = useToast();
  const isDarkMode = useIsDarkMode();

  const updateKeyResult = useUpdateKeyResult({
    onSuccess: () => {
      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });
  const deleteKeyResult = useDeleteKeyResult();

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure({ id: 'delete' });
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure({ id: 'edit' });

  const handleComplete = useCallback(() => {
    updateKeyResult.mutate({
      id: keyId,
      dueDate,
      name,
      isStarred,
      isCompleted: !isCompleted,
      projectId,
    });
  }, [updateKeyResult, keyId, dueDate, name, isStarred, isCompleted, projectId]);

  const handleStar = useCallback(() => {
    updateKeyResult.mutate({
      id: keyId,
      dueDate,
      name,
      isCompleted,
      isStarred: !isStarred,
      projectId,
    });
  }, [updateKeyResult, keyId, dueDate, name, isCompleted, isStarred, projectId]);

  const handleDelete = useCallback(async () => {
    await deleteKeyResult.mutateAsync({ id: keyId, projectId });

    toast({ title: 'Key Result deleted successfully', status: 'success', duration: 3000, isClosable: true });
  }, [deleteKeyResult, keyId, projectId, toast]);

  const handleOpen = useCallback(() => {
    onOpen();
    onEditOpen();
  }, [onEditOpen, onOpen]);

  const handleClose = useCallback(() => {
    onClose();
    onEditClose();
  }, [onClose, onEditClose]);

  return (
    <Flex
      alignItems="center"
      gap={rem(10)}
      width="full"
      height={rem(32)}
      marginBottom={rem(8)}
      padding={`${rem(6)} ${rem(7)}`}
      background={isStarred ? generateLinearGradient(projectCategoryColor) : 'inherit'}
      opacity={isCompleted || isArchived ? '0.6' : '1'}
      borderRadius="4"
      cursor={isArchived ? 'not-allowed' : ''}
      backgroundColor="background-primary"
    >
      <IconButton
        width={rem(20)}
        minWidth={rem(20)}
        height={rem(20)}
        minHeight={rem(20)}
        border="none"
        pointerEvents={isArchived ? 'none' : 'unset'}
        cursor="pointer"
        aria-checked={isCompleted}
        aria-label="Complete"
        icon={
          isCompleted ? (
            <Icon as={IconComplete} boxSize={rem(14)} color="#2b7fa8" />
          ) : (
            <Icon as={IconIncomplete} boxSize={rem(14)} />
          )
        }
        isActive={isCompleted}
        isDisabled={updateKeyResult.isLoading}
        onClick={handleComplete}
        role="checkbox"
        variant="unstyled"
      />
      <Text as="span" textStyle="actionRowIndex">
        {index}
      </Text>
      <EllipsisTooltipText flex={1} textStyle="actionRowTitle" textAlign="left">
        {name}
      </EllipsisTooltipText>
      <Flex alignItems="center" gap={rem(60)}>
        <Text textStyle="actionRowTitle" textAlign="left">
          {format(new Date(dueDate), 'MMM d')}
        </Text>
        <HStack gap={rem(2)}>
          <PreferredButton
            isStarred={isStarred}
            iconColor={isDarkMode ? 'white.500' : 'gray.500'}
            onClick={isCompleted ? undefined : handleStar}
            padding={rem(2.5)}
            readOnly={isCompleted}
            pointerEvents={isArchived ? 'none' : 'unset'}
          />
          {!isCompleted && !isArchived && (
            <ThreeDotsKeyResultButton
              onCreateAction={() => onCreateAction(name)}
              onCreateBlock={() => onCreateBlock(name)}
              onRemove={onDeleteOpen}
              onEdit={handleOpen}
            />
          )}
          <ManageKeyResultModal
            isOpen={isEditOpen}
            onClose={handleClose}
            projectId={projectId}
            keyResult={keyResult}
            onDeleteOpen={onDeleteOpen}
          />
          <DialogModal
            isOpen={isDeleteOpen}
            title="Confirm"
            message="Do you really want to delete this key result?"
            onCancel={onDeleteClose}
            onConfirm={handleDelete}
            confirmText="Delete"
            confirmVariant="danger"
            modalSize="xl"
          />
        </HStack>
      </Flex>
    </Flex>
  );
}

export default memo(KeyResultItem);
