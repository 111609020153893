import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import { FadeInOutVariants, SlideXInOutVariants } from '@/constants/animations';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { useHeaderHeight } from '@/hooks/useHeaderHeight';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import DashboardLayout from '@/layouts/Dashboard';
import FullCaptureListFAB from '@/pages/WeeklyCapturePage/Sections/FullCaptureListFAB';
import FullCaptureListOffset from '@/pages/WeeklyCapturePage/Sections/FullCaptureListOffset';
import LeftSection from '@/pages/WeeklyCapturePage/Sections/LeftSection';
import RightSection from '@/pages/WeeklyCapturePage/Sections/RightSection';
import { useActions } from '@/services/action/hooks';
import { useCategories } from '@/services/categories/hooks';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useActionsCategories } from '@/stores/useActionsCategories';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { HomeBackground } from '@/theme/StaticImages';
import { Category } from '@/types/category';
import {
  getActionsByCategoryId,
  getCompletedActions,
  getDailyActions,
  getIncompleteActions,
  getWeeklyActions,
} from '@/utils/action';
import { getCategoryById, resolveCategoryImageByName } from '@/utils/category';
import { getColorFromToken } from '@/utils/color';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { trackCreateCategorySelected } from '@/utils/tracking';
import { Flex, SimpleGrid, VStack, useDisclosure } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { isEmpty, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

const blue1000 = getColorFromToken('blue.1000', '1');
const gray50 = getColorFromToken('gray.50', '1');
const blue1000_a50 = getColorFromToken('blue.1000', '0.50');

const MotionFlex = motion(Flex);

function WeeklyCapturePage() {
  const [showOffscreen, setShowOffscreen] = useState(false);
  const { pageNavigationEffect } = useAnimatePlanPages();
  const isDarkMode = useIsDarkMode();

  const controlsAnimation = useAnimation();

  const {
    isOpen: isCreateCategoryOpen,
    onOpen: onCreateCategoryOpen,
    onClose: onCreateCategoryClose,
  } = useDisclosure({ id: 'createNewCategory' });

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const selectedCategory = useActionsCategories((state) => state.selectedCategory);
  const updateSelectedCategory = useActionsCategories((state) => state.updateSelectedCategory);
  const isDailyPlanPage = useIsDailyPlanPage();
  const [bgImageWidth, setBgImageWidth] = useState(0);
  const [bgImageHeight, setBgImageHeight] = useState(0);

  const { data: weeklyPlanId } = useWeeklyPlanId(selectedDate);
  const { data: categoriesList, isLoading: isCategoriesLoading } = useCategories();
  const { data: actions, isLoading: isLoadingActions } = useActions();

  const getSortedActionsByCategoryId = useCallback(
    (categoryId: string) => {
      if (!actions?.action) {
        return [];
      }

      const categoryActions = getActionsByCategoryId(actions.action, categoryId);

      const response = isDailyPlanPage
        ? getDailyActions(categoryActions, selectedDate)
        : getWeeklyActions(categoryActions, weeklyPlanId ?? '');

      return orderBy(response, 'categoryOrder', 'asc');
    },
    [actions?.action, selectedDate, weeklyPlanId, isDailyPlanPage],
  );

  const categories = useMemo(() => {
    if (!categoriesList?.category) {
      return [];
    }

    const fullCategoriesList = categoriesList.category
      .filter((c) => !c.isArchived)
      .map((category) => {
        const actions = getSortedActionsByCategoryId(category.id);

        return {
          ...category,
          incompleteActions: getIncompleteActions(actions, 'categoryOrder').filter(
            (a) => !a.project?.isArchived && !a.category.isArchived,
          ),
          completedActions: getCompletedActions(actions, 'modifiedAt').filter(
            (a) => !a.project?.isArchived && !a.category.isArchived,
          ),
        };
      });
    // Return 'uncategorized' if filteredCategories is empty
    if (fullCategoriesList.length === 0 && !isEmpty(categoriesList?.category)) {
      const getUncategorized = categoriesList.category.find(
        (el) => el.name.toLowerCase() === 'uncategorized',
      ) as Category;

      return [
        {
          ...getUncategorized,
          incompleteActions: [],
          completedActions: [],
        },
      ];
    }

    return fullCategoriesList;
  }, [categoriesList?.category, getSortedActionsByCategoryId]);

  const category = useMemo(() => {
    if (selectedCategory?.id) {
      return getCategoryById(categories, selectedCategory?.id);
    }
    return categories?.find((category) => category.name.toLowerCase() === 'uncategorized');
  }, [categories, selectedCategory?.id]);

  const resetCategorySelected = useCallback(() => {
    updateSelectedCategory(categories?.[0]);
  }, [categories, updateSelectedCategory]);

  const toggleOffscreen = useCallback(() => {
    setShowOffscreen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    controlsAnimation?.start('visible');
  }, [showOffscreen, controlsAnimation]);

  useEffect(() => {
    if (categories?.find((el) => el?.id === selectedCategory?.id) === undefined && showOffscreen) {
      resetCategorySelected();
    }

    if (categories?.length > 0 && !isCategoriesLoading && !selectedCategory) {
      resetCategorySelected();
    }
  }, [categories, isCategoriesLoading, resetCategorySelected, selectedCategory, showOffscreen]);

  useEffect(() => {
    return () => {
      updateSelectedCategory(null);
    };
  }, [updateSelectedCategory]);

  useEffect(() => {
    setBgImageWidth(window.innerWidth);
    setBgImageHeight(Math.ceil(window.innerHeight * 0.7));
  }, []);

  const categoryImageUrl = `${category?.selectedImageUrl}${category?.selectedImageUrl ? `&w=${bgImageWidth}&dpr=2&q=37` : ''}`;
  const backgroundImageUrl =
    bgImageWidth && bgImageHeight && category?.selectedImageUrl
      ? resolveCategoryImageByName(category?.selectedImageUrl) +
        (category?.selectedImageUrl.includes('images.unsplash.com')
          ? `&w=${bgImageWidth}&h=${bgImageHeight}&fit=crop&crop=faces%2Cedges&dpr=2&q=37`
          : '')
      : '';

  const headerHeight = useHeaderHeight();

  return (
    <DashboardLayout pageTitle="Dashboard" padding="0" position="relative" forceShowSecondaryNav={showOffscreen}>
      <motion.div initial="initial" animate="in" exit="out" variants={pageNavigationEffect} transition={pageTransition}>
        <FullCaptureListOffset isVisible={!showOffscreen} />

        {showOffscreen && (
          <>
            <MotionFlex
              position="fixed"
              key={categoryImageUrl} // Important for triggering animation on change
              variants={FadeInOutVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ duration: 0.5 }}
              width="full"
              height={`calc(100vh - ${rem(headerHeight)})`}
              background={`linear-gradient(0deg, ${isDarkMode ? blue1000 : gray50} 0%, ${blue1000_a50} 100%), url(${
                backgroundImageUrl ?? HomeBackground
              }), lightgray 0px -257.529px / 122.578% 155.719% no-repeat`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundColor="blue.1000"
            />
            <Flex
              position="relative"
              overflow="auto"
              overflowX="hidden"
              height="full"
              paddingTop={rem(152)}
              paddingX={rem(16)}
            >
              <SimpleGrid gap={rem(90)} width="full" columns={2} paddingX={rem(12)}>
                {/* Left column */}
                <VStack alignItems="start" gap={rem(21)} width="full">
                  <LeftSection
                    categories={categories.filter((c) => !c.isArchived)}
                    isCategoriesLoading={isCategoriesLoading}
                    controlsAnimation={controlsAnimation}
                    onCreateCategoryOpen={() => {
                      onCreateCategoryOpen();
                      trackCreateCategorySelected('planner');
                    }}
                  />
                </VStack>

                {/* Right column */}
                <motion.div
                  initial="hidden"
                  animate={controlsAnimation}
                  variants={SlideXInOutVariants}
                  transition={{ duration: 0.2 }}
                  style={{
                    marginBottom: rem(80),
                  }}
                >
                  <VStack alignItems="start" gap={rem(16)} width="full">
                    <RightSection
                      category={category}
                      actions={actions}
                      isCategoriesLoading={isCategoriesLoading}
                      isLoadingActions={isLoadingActions}
                      getSortedActionsByCategoryId={getSortedActionsByCategoryId}
                    />
                  </VStack>
                </motion.div>
              </SimpleGrid>
            </Flex>
          </>
        )}

        <FullCaptureListFAB
          onClick={toggleOffscreen}
          buttonProps={{
            left: '45%',
            translateX: '-45%',
          }}
          innerText={showOffscreen ? 'View Full Category List' : 'Open Category Spotlight'}
          chevronLeft={showOffscreen}
        />

        {isCreateCategoryOpen && (
          <CreateEditCategoryModal isOpen={isCreateCategoryOpen} onClose={onCreateCategoryClose} />
        )}
      </motion.div>
    </DashboardLayout>
  );
}

export default WeeklyCapturePage;
