export const CATEGORY_ARCHIVE_CONFIRMATION_MODAL_COPY =
  'By archiving this category, all projects in this category will also be archived. Any actions and RPM blocks associated with this category will be hidden from the weekly and daily plan. A category can be restored at any time.';

export const CATEGORY_RESTORE_CONFIRMATION_MODAL_COPY =
  'By restoring this category, any actions and RPM blocks associated with this category will be shown again on the weekly and daily plan. Projects within this category will remain archived, but can be restored individually.';

export const PROJECT_ARCHIVE_CONFIRMATION_MODAL_COPY =
  'By archiving this project, any actions and RPM blocks associated with this category will be hidden from the weekly and daily plan. A project can be restored at any time.';

export const PROJECT_RESTORE_FIRST_CONFIRMATION_MODAL_COPY =
  'By restoring this project, any actions and RPM blocks associated with this project will be shown again on the weekly and daily plan.';

export const PROJECT_RESTORE_SECOND_CONFIRMATION_MODAL_COPY =
  'By restoring this project, any actions and RPM blocks associated with this project will be shown again on the weekly and daily plan. Because this project belongs to an archived category, the category and all of its actions and blocks will be restored as well.';
