import { DuplicateActionScheduleType } from '@/components/DuplicateActionModal';
import { DAY, WEEK, WEEKDAYS } from '@/constants/calendar';
import { CATEGORIES_ICONS } from '@/constants/category';
import { BlockEvent } from '@/gql/blockEvent/types';
import { CategoryEvent } from '@/gql/categoryEvent/types';
import { CronofyProfile } from '@/gql/cronofy/types';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { IconApple, IconExchange, IconGmail, IconLiveConnect, IconOffice365 } from '@/theme/icons';
import { Action, PlannedAction, isPlannedAction } from '@/types/actions';
import { Block } from '@/types/block';
import { EventStatus, MyEvent } from '@/types/calendar';
import { Category } from '@/types/category';
import { ExternalEventType } from '@/types/myPlan';
import {
  add,
  addDays,
  differenceInHours,
  differenceInMinutes,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  isThisWeek as isThisWeekFns,
  parse,
  setHours,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { dateFnsLocalizer } from 'react-big-calendar';

import { fixUncategorizedName, humanDuration } from '.';
import { convertToMinutes } from './events';

const locales = {
  'en-US': enUS,
};

const providerIcon = new Map();
providerIcon.set('google', IconGmail);
providerIcon.set('apple', IconApple);
providerIcon.set('exchange', IconExchange);
providerIcon.set('live_connect', IconLiveConnect);
providerIcon.set('office365', IconOffice365);

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (
    date: number | Date,
    options?:
      | {
          locale?: Locale | undefined;
          weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
        }
      | undefined,
  ) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
  getDay,
  locales,
});

const timeReadableConvert = (num: number) => {
  const hours = num / 60;
  const chours = Math.floor(hours);
  const minutes = (hours - chours) * 60;
  const cminutes = Math.round(minutes);

  if (cminutes > 0) {
    return `${chours}h ${cminutes}m`;
  }
  return `${chours}h`;
};

const minutesToDurationString = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes - hours * 60;
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const durationString = `${hoursString}:${minutesString}:00`;
  return durationString;
};

const getEventLength = (event: { end: Date; start: Date }) => {
  return differenceInHours(new Date(event?.end), new Date(event?.start)) >= 1
    ? `${timeReadableConvert(differenceInMinutes(new Date(event?.end), new Date(event?.start)))}`
    : `${differenceInMinutes(new Date(event?.end), new Date(event?.start))}m`;
};

const startOfTheWeek = (date: Date) => {
  return startOfWeek(date, {
    weekStartsOn: 1,
  });
};

const endOfTheWeek = (date: Date) => {
  return endOfWeek(date, {
    weekStartsOn: 1,
  });
};

export const startOfTheMonth = (date: Date) => {
  return startOfMonth(date);
};

export const endOfTheMonth = (date: Date) => {
  return endOfMonth(date);
};

const getEventProvider = (
  currentElement: Record<string, string> | ExternalEventType,
  cronofyProfiles?: CronofyProfile[],
) => {
  return cronofyProfiles?.filter((element) => {
    const found = element?.calendars?.find((el) => currentElement.calendar_id === el.id);

    if (found) {
      return element?.provider;
    }
  });
};

const checkIfIsAllDay = (startDate: string, endDate: string) => {
  return !startDate.includes('T') && !endDate.includes('T');
};

export const checkEventStyles = (status: EventStatus | string, showDeclinedEvents: boolean) => {
  if (status === EventStatus.DECLINED && !showDeclinedEvents) {
    return {
      opacity: 0.3,
      textDecoration: 'line-through',
      textDecorationColor: 'black',
    };
  } else if (status === EventStatus.UNANSWERED) {
    return {
      border: '3px solid white',
    };
  }
};

const externalCalendarAdapterApi = (apiObj: Record<string, any>, cronofyProfiles?: CronofyProfile[]) => {
  return apiObj?.map((el: Record<string, string>, i: number) => {
    const allDay = checkIfIsAllDay(el.start, el.end);
    const eventProvider = getEventProvider(el, cronofyProfiles);
    const hasEventProvider = !isUndefined(eventProvider);

    return {
      id: el.event_uid,
      title: el.summary,
      start: allDay ? formatStringToDate(el.start) : new Date(el.start),
      end: allDay ? endOfDay(setHours(addDays(formatStringToDate(el.end), -1), 0)) : new Date(el.end),
      allDay,
      description: el.description,
      isExternal: true,
      duration: getEventLength({ start: new Date(el.start), end: new Date(el.end) }),
      resource: {
        color: hasEventProvider ? 'gray.400' : 'transparent',
        isExternal: hasEventProvider,
        icon: hasEventProvider ? providerIcon.get(eventProvider?.[0]?.provider) : null,
        provider: eventProvider?.[0]?.provider,
      },
      participationStatus: el.participation_status,
    };
  });
};

const externalCalendarMyEventAdapterApi = (apiObj: any, cronofyProfiles?: CronofyProfile[]): MyEvent[] => {
  if (!apiObj) return [];

  return apiObj.map((el: Record<string, string>) => {
    const allDay = checkIfIsAllDay(el.start, el.end);
    const eventProvider = getEventProvider(el, cronofyProfiles);
    const hasEventProvider = !isUndefined(eventProvider);

    return {
      id: el.event_uid,
      title: el.summary,
      start: allDay ? formatStringToDate(el.start) : new Date(el.start),
      end: allDay ? endOfDay(setHours(addDays(formatStringToDate(el.end), -1), 0)) : new Date(el.end),
      allDay,
      description: el.description,
      isExternal: true,
      duration: getEventLength({ start: new Date(el.start), end: new Date(el.end) }),
      resource: {
        color: hasEventProvider ? 'gray.400' : 'transparent',
        isExternal: hasEventProvider,
        icon: hasEventProvider ? providerIcon.get(eventProvider?.[0]?.provider) : null,
        planned: allDay,
      },
      participationStatus: el.participation_status,
    };
  });
};

const externalEventFormatDuration = (differenceInMinutes: number) => {
  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = differenceInMinutes % 60;
  const seconds = 0;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

const calendarAdapterApi = (action: PlannedAction): MyEvent => {
  // After upgrading date-fns, a new bug appeared in the code, making us realise
  // that this function was being called with actions that had scheduledDate set
  // to null (thanks to a lot of unsafe typescript "as" usages). Previously the
  // utcToLocalDate function was returning Invalid Date, and for some reason the
  // app managed to work with it. But the new date-fns makes utcToLocalDate
  // throw error and crash the app.
  // I tried to improve the types and fix the code so this function would only
  // be called with planned actions, but I couldn't fix all the instances, as
  // that requires a really huge refactor, very risky, basically a full
  // rewrite of the way we generate events to show on the calendars.
  // So for the time being I decided to just log the invalid calls and restore
  // the old broken but functioning behaviour. Hopefully one day we'll rewrite
  // this whole thing with better code.
  let localDate;
  if (action && isPlannedAction(action)) {
    localDate = utcToLocalDate(action.scheduledDate, action.scheduledTime, action.timezone, action.gmtOffset);
  } else {
    console.error('Invalid action passed to calendarAdapterApi', action);
    localDate = new Date(NaN);
  }

  return {
    id: action?.id,
    eventId: action?.event?.id,
    allDay: false,
    description: '',
    title: action?.title,
    start: localDate,
    end: add(localDate, { minutes: convertToMinutes(action.duration) }),
    noScheduledTime: action.scheduledTime === null || !action.scheduledTime,
    duration: humanDuration(convertToMinutes(action.duration) * 60),
    status: action?.progressStatus,
    resource: {
      color: action?.category?.color || 'gray.400',
      isExternal: !!action.isExternal,
      icon: action.isExternal
        ? (action.icon as React.FunctionComponent<React.SVGProps<SVGSVGElement>>)
        : CATEGORIES_ICONS?.[action?.category?.icon ?? 'uncategorized'],
      planned: action.scheduledTime === null,
    },
    dateOfStarring: action.dateOfStarring,
    isLocked: action.isLocked,
    __metadata: {
      action,
    },
  };
};

const categoryEventAdapterApi = (categoryEvent: CategoryEvent, category?: Category): MyEvent => {
  const localDate = utcToLocalDate(
    categoryEvent.scheduledDate,
    categoryEvent.scheduledTime,
    categoryEvent.timezone,
    categoryEvent.gmtOffset,
  );

  return {
    id: categoryEvent.id,
    title: fixUncategorizedName(category?.name ?? '[UNKNOWN]'),
    description: '',
    start: localDate,
    end: add(localDate, { minutes: convertToMinutes(categoryEvent.duration) }),
    duration: humanDuration(convertToMinutes(categoryEvent.duration) * 60),
    allDay: false,
    resource: {
      color: category?.color || 'gray.400',
      icon: CATEGORIES_ICONS[category?.icon ?? 'uncategorized'],
      isExternal: false,
      planned: false,
    },
    dateOfStarring: null,
    isLocked: false,
    __metadata: {
      categoryEvent,
    },
  };
};

const blockEventAdapterApi = (blockEvent: BlockEvent, block?: Block): MyEvent => {
  const localDate = utcToLocalDate(
    blockEvent.scheduledDate,
    blockEvent.scheduledTime,
    blockEvent.timezone,
    blockEvent.gmtOffset,
  );

  return {
    id: blockEvent.id,
    title: block?.result ?? '',
    description: '',
    start: localDate,
    end: add(localDate, { minutes: convertToMinutes(blockEvent.duration) }),
    duration: humanDuration(convertToMinutes(blockEvent.duration) * 60),
    allDay: false,
    resource: {
      color: block?.category?.color || 'gray.400',
      icon: CATEGORIES_ICONS[block?.category?.icon ?? 'uncategorized'],
      isExternal: false,
      planned: false,
    },
    dateOfStarring: null,
    isLocked: false,
    __metadata: {
      blockEvent,
    },
  };
};

const formatDateToString = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

const formatStringToDate = (date: string) => {
  if (/[TZ]/.test(date)) {
    return new Date(date);
  }

  return parse(date, 'yyyy-MM-dd', new Date());
};

const utcToLocalDate = (
  scheduledDate: string,
  scheduledTime: string | null,
  timeZone?: string,
  gmtOffset?: string | null,
) => {
  if (!scheduledTime) {
    return DateTime.fromISO(scheduledDate).toJSDate();
  }

  if (!gmtOffset) {
    return new Date(`${scheduledDate} ${scheduledTime} UTC`);
  }

  const newUTCDate = DateTime.fromISO(`${scheduledDate}T${scheduledTime}`, { zone: 'UTC' });

  const localActionDate = newUTCDate.setZone(timeZone);

  // Modify the regex to also accept minutes in the GMT format, for example "GMT-05:30"
  const match = gmtOffset.match(/^GMT([+-])(\d{2})(?::(\d{2}))?$/);

  if (!match) throw new Error('Invalid GMT format');

  const sign = match[1] === '+' ? 1 : -1;
  const hoursOffset = parseInt(match[2], 10);
  const minutesOffset = match[3] ? parseInt(match[3], 10) : 0;

  // Calculate the total offset in minutes, including hours and minutes
  const offsetStored = sign * (hoursOffset * 60 + minutesOffset);

  const currentOffset = localActionDate.offset;

  let adjustedDateTime = localActionDate;

  if (currentOffset !== offsetStored) {
    const offsetDifference = offsetStored - currentOffset;
    adjustedDateTime = localActionDate.plus({ minutes: offsetDifference });
  }

  return adjustedDateTime.toJSDate();
};

export const localDateToUTC = (scheduledDateTime: string | Date) => {
  let localDate: DateTime;
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (scheduledDateTime instanceof Date) {
    localDate = DateTime.fromJSDate(scheduledDateTime, { zone: currentTimezone });
  } else if (typeof scheduledDateTime === 'string') {
    localDate = DateTime.fromFormat(scheduledDateTime, 'yyyy-MM-dd HH:mm', { zone: currentTimezone });
  } else {
    throw new Error('scheduledDateTime must be a string o Date');
  }

  if (!localDate.isValid) {
    throw new Error(`Invalid Date: ${scheduledDateTime}`);
  }

  const utcDate = localDate.toUTC();

  return {
    scheduledDate: utcDate.toFormat('yyyy-MM-dd'),
    scheduledTime: utcDate.toFormat('HH:mm:ss'),
    gmtOffset: getGMTOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, utcDate.toJSDate()),
  };
};

export const getGMTOffset = (timeZone: string, date?: Date) => {
  const longOffsetFormatter = new Intl.DateTimeFormat('en-US', { timeZone, timeZoneName: 'longOffset' });
  const longOffsetString = longOffsetFormatter.format(date ?? new Date());
  return (longOffsetString.split(', ')[1] === 'GMT' ? 'GMT+00:00' : longOffsetString.split(', ')[1]) || 'GMT';
};

export const localToGivenTimezoneDateToUTC = (scheduledDateTime: Date, timeZone: string) => {
  // time is given in the user's current timezone but should actually be selected timezone, so we need to swap it first
  const gmtOffset = getGMTOffset(timeZone);
  return localDateToUTC(new Date(`${format(scheduledDateTime, 'MMM dd yyyy HH:mm:ss')} ${gmtOffset ?? 'GMT'}`));
};

/**
 * Return a list of translated weekdays within a range
 * @param startDate
 * @param endDate
 * @param pattern (optional)
 */
const getDaysWithinDatesRange = (startDate: Date, endDate: Date): string[] => {
  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  return days.map((item) => format(item, 'yyyy-MM-dd HH:mm:ss'));
};

const formatDateLabelByPlan = (plan: typeof DAY | typeof WEEK, date: Date) => {
  if (!plan) return;

  if (plan === DAY) {
    return format(date, 'E, MMM dd');
  } else if (plan === WEEK) {
    return `${format(startOfTheWeek(date), 'E dd')} - ${format(endOfTheWeek(date), 'E dd')}`;
  }
};

const filterActionsSameDay = (actions: Action[], selectedDate: Date) => {
  // Actions can be out of bounds of the selectedDate due UTC midnights
  // when there is scheduledTime. The queries for Daily plan should include
  // minus/plus 1 day and filter actions of same day using this function
  return actions.filter((action) => {
    if (action.scheduledTime) {
      return isSameDay(selectedDate, new Date(`${action.scheduledDate} ${action.scheduledTime} UTC`));
    }
    if (action.scheduledDate) {
      return isSameDay(selectedDate, formatStringToDate(action.scheduledDate));
    }
  });
};

const filterActionsSameWeek = (actions: Action[], selectedDate: Date) => {
  // Actions can be out of bounds of the selectedDate due UTC midnights
  // when there is scheduledTime. The queries for Weekly plan should include
  // minus/plus 1 day and filter actions of same day using this function
  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  if (!weeklyPlanId) {
    return [];
  }

  return actions.filter((action) => action.weeklyPlanId === weeklyPlanId);
};

const isThisWeek = (selectedDate: Date) => isThisWeekFns(selectedDate, { weekStartsOn: 1 });

const isPastWeek = (selectedDate: Date) => {
  const startDayOfNextWeek = startOfWeek(selectedDate, { weekStartsOn: 1 });
  const startDayOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

  return isBefore(startDayOfNextWeek, startDayOfCurrentWeek);
};

const isFutureWeek = (selectedDate: Date) => {
  const startDayOfNextWeek = startOfWeek(selectedDate, { weekStartsOn: 1 });
  const startDayOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

  return !isPastWeek(selectedDate) && isAfter(startDayOfNextWeek, startDayOfCurrentWeek);
};

const parseTimeToMinutes = (timeString: string): number => {
  const parts = timeString.split(' ');
  let totalMinutes = 0;

  for (const part of parts) {
    if (part.includes('h')) {
      const hours = parseInt(part.replace('h', ''), 10);
      totalMinutes += hours * 60;
    } else if (part.includes('m')) {
      const minutes = parseInt(part.replace('m', ''), 10);
      totalMinutes += minutes;
    }
  }

  return totalMinutes;
};

const getRepeatDatesFromSchedule = (
  repeatSchedule: DuplicateActionScheduleType,
  actionScheduledDate: string,
  actionScheduledTime: string | null,
  timeZone?: string,
  gmtOffset?: string,
): { scheduledDate: string; scheduledTime: string | null; timeZone?: string; gmtOffset?: string }[] => {
  const repeatDate = utcToLocalDate(actionScheduledDate, actionScheduledTime, timeZone, gmtOffset);
  const repeatOnDates: Date[] = [];
  if (repeatSchedule.intervalType === 0) {
    // Day repeat
    for (let i = 0; i < repeatSchedule.repeatTimes; i++) {
      repeatDate.setDate(repeatDate.getDate() + 1);
      repeatOnDates.push(new Date(repeatDate));
    }
  } else if (repeatSchedule.intervalType === 1) {
    // Week repeat
    const actionDayOfWeekIndex = repeatDate.getDay();
    let lastScheduledWeekday = WEEKDAYS[actionDayOfWeekIndex];
    const weekdays = repeatSchedule.onWeekdays;
    const sortedWeekdays = WEEKDAYS.filter((weekday) => weekdays.includes(weekday));
    let nextWeekday = lastScheduledWeekday;
    for (let i = 0; i < repeatSchedule.repeatTimes; i++) {
      nextWeekday = sortedWeekdays[(sortedWeekdays.indexOf(nextWeekday) + 1) % sortedWeekdays.length];
      const nextWeekdayIndex = WEEKDAYS.indexOf(nextWeekday);
      const lastScheduledWeekdayIndex = WEEKDAYS.indexOf(lastScheduledWeekday);
      const daysToAdd =
        nextWeekdayIndex === lastScheduledWeekdayIndex
          ? WEEKDAYS.length
          : (WEEKDAYS.length + nextWeekdayIndex - lastScheduledWeekdayIndex) % WEEKDAYS.length;
      repeatDate.setDate(repeatDate.getDate() + daysToAdd);
      lastScheduledWeekday = nextWeekday;
      repeatOnDates.push(new Date(repeatDate));
    }
  }
  return repeatOnDates.map((date) => {
    if (actionScheduledTime) return localDateToUTC(date);
    return {
      scheduledDate: formatDateToString(date),
      scheduledTime: null,
      gmtOffset,
      timeZone,
    };
  });
};

const getWeekBoundariesForDates = (
  dates: { scheduledDate: string; scheduledTime: string | null; timeZone?: string; gmtOffset?: string }[],
) => {
  const acc: [Date, Date][] = [];
  return dates.reduce((acc, dateStruct) => {
    const realDate = utcToLocalDate(
      dateStruct.scheduledDate,
      dateStruct.scheduledTime,
      dateStruct.timeZone,
      dateStruct.gmtOffset,
    );
    const monday = startOfTheWeek(realDate);
    if (!acc.find((d) => isSameDay(d[0], monday))) {
      acc.push([monday, endOfTheWeek(realDate)]);
    }
    return acc;
  }, acc);
};

export {
  calendarAdapterApi,
  checkIfIsAllDay,
  endOfTheWeek,
  externalCalendarAdapterApi,
  externalCalendarMyEventAdapterApi,
  getRepeatDatesFromSchedule,
  getWeekBoundariesForDates,
  blockEventAdapterApi,
  categoryEventAdapterApi,
  externalEventFormatDuration,
  filterActionsSameDay,
  formatDateLabelByPlan,
  formatDateToString,
  formatStringToDate,
  getDaysWithinDatesRange,
  getEventLength,
  getEventProvider,
  isFutureWeek,
  isPastWeek,
  isThisWeek,
  locales,
  localizer,
  minutesToDurationString,
  providerIcon,
  startOfTheWeek,
  timeReadableConvert,
  utcToLocalDate,
  parseTimeToMinutes,
  filterActionsSameWeek,
};
