import rem from '@/utils/rem';
import { Editable, EditableInput, EditablePreview, EditableTextarea, ResponsiveValue } from '@chakra-ui/react';
import * as CSS from 'csstype';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

type Props = {
  control: any;
  fontSize?: string;
  isSubmitting: boolean;
  name: string;
  placeholder: string;
  inputType?: 'input' | 'textarea';
  onSubmit: (value: string | undefined) => void;
  onBlur: (value: string, preValue: string) => void;
  isHidden?: boolean;
  cursor?: React.CSSProperties['cursor'];
  isArchived?: boolean;
};

const EditableTextnput = ({
  cursor,
  control,
  fontSize = '3xl',
  inputType = 'input',
  isSubmitting,
  name,
  placeholder,
  onBlur,
  onSubmit,
  isHidden,
  isArchived,
}: Props) => {
  const props = useMemo(
    () => ({
      borderRadius: 0,
      fontSize,
      height: inputType === 'textarea' ? rem(118) : 'auto',
      paddingBottom: rem(8),
      color: 'text-primary',
      fontWeight: 500,
      border: 'none',
      borderBottomWidth: rem(1),
      borderBottomStyle: 'solid' as ResponsiveValue<CSS.Property.BorderBottomStyle>,
      borderBottomColor: 'stroke-primary',
      _hover: {
        boxShadow: 'none',
      },
      _focusVisible: {
        boxShadow: 'none',
        borderColor: 'cyan.400 !important',
      },
      _invalid: { boxShadow: 'none', borderBottom: '1px solid red' },
      _placeholder: {
        color: 'text-tertiary',
        opacity: 0.3,
        fontSize,
        fontWeight: 500,
      },
      resize: 'none' as ResponsiveValue<CSS.Property.Resize>,
      onFocus: (e: any) =>
        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length),
    }),
    [fontSize, inputType],
  );
  const input = useMemo(() => <EditableInput {...props} />, [props]);
  const textarea = useMemo(() => <EditableTextarea {...props} />, [props]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, onChange } }) => (
        <Editable
          ref={ref}
          display={isHidden ? 'none' : ''}
          overflow="unset"
          width="full"
          maxWidth="100%"
          height="full"
          paddingBottom={rem(8)}
          fontSize={fontSize}
          fontWeight={500}
          defaultValue={value ?? ''}
          isDisabled={isSubmitting}
          onBlur={(preValue) => onBlur(value, preValue)}
          onChange={onChange}
          onSubmit={(value) => onSubmit(value)}
          placeholder={placeholder}
          selectAllOnFocus={false}
          submitOnBlur
          value={value ?? ''}
        >
          <EditablePreview
            overflow={inputType === 'textarea' ? 'auto' : 'unset'}
            width="full"
            height={inputType === 'textarea' ? rem(118) : 'full'}
            marginBottom={inputType === 'textarea' ? 0 : rem(4)}
            color={isSubmitting ? 'gray.400' : 'text-primary'}
            fontSize={fontSize}
            fontWeight={500}
            opacity={value ? 1 : 0.5}
            border="none"
            borderBottomWidth={rem(1)}
            borderBottomStyle={isArchived ? 'none' : 'solid'}
            borderBottomColor="stroke-primary"
            cursor={cursor}
          />
          {inputType === 'textarea' ? textarea : input}
        </Editable>
      )}
    />
  );
};

export default EditableTextnput;
